import { useContext, useRef } from 'react'
import { Box, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { ApplicationsGrid } from './applicationsGrid'
import { UserContext } from '../../common/userContext'
import { PageHeader } from '../common/pageHeader'

export default function Applications() {
    const contextData = useContext(UserContext)
    const applicationsGridRef = useRef(null)

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 1,
                width: 1,
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                '& .super-app-theme--expired': {
                    bgcolor: 'mistyrose',
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                }}
            >
                <PageHeader title={`Credential\u00a0Checklists`} />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-end',
                        mr: 0,
                        mb: 1,
                    }}
                >
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        sx={{ mr: 0 }}
                        onClick={() => {
                            const handle = applicationsGridRef.current
                            if (handle === null) return
                            ;(handle as any).addChecklist()
                        }}
                    >
                        Add
                    </Button>
                </Box>
            </Box>
            {!contextData.user.isAdminOrRecruiter() && (
                <p>
                    Applications for roles you are currently or have previously applied for will appear here. You will
                    be notified of any action items required.
                </p>
            )}
            <ApplicationsGrid ref={applicationsGridRef} />
        </Box>
    )
}
