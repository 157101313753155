import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { PageInfo } from "./components/common/pageInfo";
import { MainWindow } from "./components/mainWindow";
import { Loading } from "./components/common/loading";
import { BrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import {
    retrieveLedgeUser,
    retrieveDocuments,
    retrieveDocumentTypes,
    retrieveTemplates,
} from "./common/apiUtils";
import { User, Document, DocumentType, Application } from "./API";

import {
    UserContext,
    ContextDataInterface,
    UserWrapper,
} from "./common/userContext";

function App() {
    const { user, error, getIdTokenClaims, getAccessTokenSilently, logout } =
        useAuth0();
    const [contextData, setContextData] = useState<ContextDataInterface>();
    const [authenticationError, setAuthenticationError] = useState(
        error?.message
    );

    

    useEffect(() => {
        const initialiseContext = async () => {

            if (contextData) {
                await contextData.user.checkSessionActive();
                return;
            }

            const setLedgeUser = (user?: User | undefined) => {
                setContextData((current) => {
                    if (!current) return undefined;
                    return { ...current, ledgeUser: user };
                });
            };

            const setDocuments = (documents: Document[]) => {
                setContextData((current) => {
                    if (!current) return;
                    return {
                        ...current,
                        documents: documents,
                    };
                });
            };

            const setTemplates = (templates: Application[]) => {
                setContextData((current) => {
                    if (!current) return;
                    return {
                        ...current,
                        templates: templates,
                    };
                });
            };

            const setDocumentTypes = (documentTypes: DocumentType[]) => {
                setContextData((current) => {
                    if (!current) return;
                    return {
                        ...current,
                        documentTypes: documentTypes,
                    };
                });
            };

            if (!user) {
                // Redirect to error??
                setAuthenticationError(
                    "Could not retrieve idToken!"
                );
                return;
            }

            const userWrapper = new UserWrapper(
                user,
                getAccessTokenSilently,
                getIdTokenClaims,
                logout
            );

            // https://auth0.com/docs/authenticate/login/configure-silent-authentication
            const error = await userWrapper.initialise();

            if (error) {
                setAuthenticationError(error.message);
                return;
            }

            const result = Promise.all([
                retrieveLedgeUser(userWrapper, userWrapper.sub),
                retrieveDocuments(userWrapper, userWrapper.sub),
                retrieveDocumentTypes(userWrapper),
                retrieveTemplates(userWrapper),
            ]);

            const [
                userResult,
                documentsResult,
                documentTypesResult,
                templatesResult,
            ] = await result;

            const newContextData: ContextDataInterface = {
                user: userWrapper,
                ledgeUser: userResult.Result,
                documents: documentsResult.Result ?? [],
                documentTypes: documentTypesResult.Result ?? [],
                templates: templatesResult.Result ?? [],
                setLedgeUser: setLedgeUser,
                setDocuments: setDocuments,
                setDocumentTypes: setDocumentTypes,
                setTemplates: setTemplates,
            };

            setContextData(newContextData);
        };

        if (!contextData && !authenticationError) {
            initialiseContext();
        }
    }, []);

    if (authenticationError)
        return <PageInfo color="error" message={authenticationError} />;

    if (contextData)
        return (
            <UserContext.Provider value={contextData}>
                <BrowserRouter>
                    <MainWindow />
                </BrowserRouter>
            </UserContext.Provider>
        );

    return <Loading />;
}

export default withAuthenticationRequired(App, {
    onRedirecting: () => <Loading />,
});