import { User, UserInput } from '../../API'
import { ContextDataInterface } from '../../common/userContext'

export type ProfileSectionProps = {
    userInput: UserInput
    gridItemWidth: number
    setValue: (name: string, value: any) => void
}

export const AreasOfSpecialization = ['CriticalCare', 'Medical', 'Surgical', 'Psychiatry', 'GP']

export const Colors = ['Plum', 'LightBlue', 'BurlyWood', 'LightPink', 'OliveDrab', 'Coral', 'PaleGoldenrod']

export const getSpecialtyColor = (specialty: string) => {
    if (specialty === 'CriticalCare') return 'Plum'
    if (specialty === 'Medical') return 'LightBlue'
    if (specialty === 'Surgical') return 'BurlyWood'
    if (specialty === 'Psychiatry') return 'LightPink'
    if (specialty === 'GP') return 'OliveDrab'
    return 'LightGray'
}

export function stringToHash(string): number {
                  
    var hash = 0;
      
    if (string.length == 0) return hash;
      
    for (var i = 0; i < string.length; i++) {
        var char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }
      
    return Math.abs(hash);
}

export const getUserColor = (context: ContextDataInterface) => {
    if(context.ledgeUser?.id === context.user.sub && context.user.isAdminOrRecruiter()) return "Gray"
    const colorId = stringToHash(context.ledgeUser?.id ?? context.user.sub) % 7
    return Colors[colorId]
}

export function getUserInput(id: string, email: string, user: User | undefined): UserInput {
    if (!user || id != user.id) {
        const newUser: UserInput = {
            id: id,
            email: email,
            phone: '',
            firstName: '',
            lastName: '',
            dateOfBirth: undefined,
            level: undefined,
            yearsOfExperience: undefined,
            currentJobTitle: undefined,
            currentJobType: undefined,
            seekingLevels: [],
            seekingSpecialities: [],
            bankName: undefined,
            bankBsb: undefined,
            bankAccountNumber: undefined,
            superannuationNumber: undefined,
            taxFileNumber: undefined,
            abnNumber: undefined,
            wwccNumber: undefined,
            wwccExpiry: undefined,
            ahpraNumber: undefined,
            prescriberNumber: undefined,
            citizenshipStatus: undefined,
            activeCampaignContactId: undefined,
            activeCampaignPendingUpdate: true,
        }
        return newUser
    }
    const existingUser: UserInput = {
        id: id,
        email: email,
        phone: user.phone,
        firstName: user.firstName,
        lastName: user.lastName,
        dateOfBirth: user.dateOfBirth,
        level: user.level,
        yearsOfExperience: user.yearsOfExperience,
        currentJobTitle: user.currentJobTitle,
        currentJobType: user.currentJobType,
        seekingLevels: user.seekingLevels,
        seekingSpecialities: user.seekingSpecialities,
        bankName: user.bankName,
        bankBsb: user.bankBsb,
        bankAccountNumber: user.bankAccountNumber,
        superannuationNumber: user.superannuationNumber,
        taxFileNumber: user.taxFileNumber,
        abnNumber: user.abnNumber,
        wwccNumber: user.wwccNumber,
        wwccExpiry: user.wwccExpiry,
        ahpraNumber: user.ahpraNumber,
        prescriberNumber: user.prescriberNumber,
        citizenshipStatus: user.citizenshipStatus,
        activeCampaignContactId: user.activeCampaignContactId,
        activeCampaignPendingUpdate: user.activeCampaignPendingUpdate,
    }
    return existingUser
}

export function getUpdatedInput(input: UserInput, name: string, value: any) {
    const updated: UserInput = {
        ...input,
        [name]: value,
    }
    return updated
}
