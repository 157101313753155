import { Storage } from 'aws-amplify'
import { Document, DocumentType, User } from '../API'
import { deleteDocument } from '../graphql/mutations'
import { ContextDataInterface } from './userContext'
import { ErrorSessionLapsed, getErrorMessage } from './errorUtils'
import { callApi } from './apiUtils'
import JsZip from 'jszip'
import FileSaver from 'file-saver'
import { Promise } from 'bluebird'
import { parsePathName } from './typeUtils'

type StorageAccessLevel = 'public' | 'protected' | 'private'

type StorageDetails = {
    level: StorageAccessLevel
    folder: string
    moveFromLevel: StorageAccessLevel
    moveFromFolder: string
}

function getUserFolder(user: User): string {
    // sub with special characters mapped, see auth0 rule, in particular '|' is an invalida principal tag value in aws
    return user.id.replace('|', '-')
}

function getStorageDetails(user: User | undefined): StorageDetails {
    if (!user) throw new Error('The user is not logged in!') // TODO REVISIT
    const sharedFolder = getUserFolder(user)
    const isLedgeRecUser = user.ledgeRecUser === 'x' 
    return isLedgeRecUser
        ? {
              level: 'public',
              folder: `${sharedFolder}/`,
              moveFromLevel: 'private',
              moveFromFolder: '',
          }
        : {
              level: 'private',
              folder: '',
              moveFromLevel: 'public',
              moveFromFolder: `${sharedFolder}/`,
          }
}

export function removeExtension(filename: string): string {
    return filename.substring(0, filename.lastIndexOf('.')) || filename
}

export function getExtension(filename: string): string {
    return filename.slice(filename.lastIndexOf('.')) || ''
    //const match = filename.match(/(\..*)$/);
    //return  match && match.length === 2 ? match[1] : "";
}

export function getFileName(document: Document) {
    const origExtension = getExtension(document.originalFileName)
    const currentExtension = getExtension(document.documentName)
    if (currentExtension === origExtension) return document.documentName
    return `${document.documentName}${origExtension}`
}

// export function getDocumentS3Key(documentId: string): string {
//     // return `${userId}_${documentId}`;
//     return `${documentId}`;
// }

export async function updateLedgeRecDocumentSharing(context: ContextDataInterface, ledgeUser: User | undefined): Promise<Error | undefined> {
    try {
        const okToProceed = await context.user.checkSessionActive()
        if (!okToProceed) return ErrorSessionLapsed
        const storageDetails = getStorageDetails(ledgeUser)

        const toMove = await Storage.list(`${storageDetails.moveFromFolder}`, {
            level: storageDetails.moveFromLevel,
        })

        if (toMove.length === 0) return undefined

        const moveOperations = Promise.map<any, string>(
            toMove,
            (documentToMove: any) => {
                // console.log(JSON.stringify(documentToMove))
                const expectedPathsLength = storageDetails.moveFromLevel === 'private' ? 2 : 3
                const paths = documentToMove.key.split('/')
                if (paths.length === 0) {
                    // === expectedPathsLength) {
                    throw new Error(`Invalid paths string when moving file ${documentToMove.key}`)
                }
                const documentId = paths[paths.length - 1]
                return Storage.copy(
                    {
                        key: `${storageDetails.moveFromFolder}${documentId}`,
                        level: storageDetails.moveFromLevel,
                    }, //, identityId: 'identityId' },
                    {
                        key: `${storageDetails.folder}${documentId}`,
                        level: storageDetails.level,
                    }
                ).then((r) => {
                    console.log(JSON.stringify(r))
                    Storage.remove(`${storageDetails.moveFromFolder}${documentId}`, {
                        level: storageDetails.moveFromLevel,
                    })
                    return r.key
                })

                // return documentToMove.key
            },
            { concurrency: 4 }
        )

        const movedDocuments = await moveOperations

        return undefined
    } catch (error) {
        const errorMessage = getErrorMessage('uploading the document', error)
        console.log(errorMessage)
        return new Error(errorMessage)
    }
}

export async function uploadDocument(
    context: ContextDataInterface,
    key: string,
    file: File
): Promise<Error | undefined> {
    try {
        const okToProceed = await context.user.checkSessionActive()
        if (!okToProceed) return ErrorSessionLapsed
        const storageDetails = getStorageDetails(context.ledgeUser)
        const result = await Storage.put(`${storageDetails.folder}${key}`, file, {
            level: storageDetails.level,
            contentType: file.type, // contentType is optional
        })
        console.log('Successfully uploaded document: %o', result)
        return undefined
    } catch (error) {
        const errorMessage = getErrorMessage('uploading the document', error)
        console.log(errorMessage)
        return new Error(errorMessage)
    }
}

// function downloadBlob(blob: any, filename: string) {
//     const url = URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = filename;
//     const clickHandler = () => {
//         setTimeout(() => {
//             URL.revokeObjectURL(url);
//             a.removeEventListener("click", clickHandler);
//         }, 150);
//     };
//     a.addEventListener("click", clickHandler, false);
//     a.click();
//     return a;
// }

export type DownLoad = {
    fileName: string
    contentType: string
    blob: any
}

function getZipDownloadFileName(document: Document, documentTypes: DocumentType[]): string {
    const fileName = getFileName(document)
    const documentType = documentTypes.find((d) => d.id === document.documentTypeId)
    const folderPath = documentType
        ? documentType.hasMany
            ? documentType.pathName
            : parsePathName(documentType.pathName).path
        : ''

    return `${folderPath}/${fileName}`
}

async function retrieveDocument(
    context: ContextDataInterface,
    document: Document,
    fileNameGetter: (Document) => string
): Promise<DownLoad> {
    try {
        const downloadFileName = fileNameGetter(document)
        if (!document.uploaded)
            return {
                fileName: downloadFileName,
                contentType: document.contentType,
                blob: undefined,
            }
        const storageDetails = getStorageDetails(context.ledgeUser)
        const result = await Storage.get(`${storageDetails.folder}${document.id}`, {
            level: storageDetails.level,
            download: true,
        })
        return {
            fileName: downloadFileName,
            contentType: document.contentType,
            blob: result?.Body,
        }
    } catch (error) {
        console.log(error)
        return {
            fileName: document.documentName,
            contentType: document.contentType,
            blob: undefined,
        }
    }
}

async function downloadDocumentsForZip(
    context: ContextDataInterface,
    documents: Document[],
    documentTypes: DocumentType[]
): Promise<DownLoad[]> {
    return Promise.map<Document, DownLoad>(
        documents,
        async (document: Document) =>
            retrieveDocument(context, document, (d) => getZipDownloadFileName(d, documentTypes)),
        { concurrency: 4 }
    )
}

function exportZip(downloads: any) {
    const zip = JsZip()
    downloads.forEach((download: any, i: any) => {
        if (!download.blob) return
        const path = download.fileName.split('/')
        var downloadZip = zip
        for (var j = 0; j < path.length; ++j) {
            const nextPath = path[j]
            if (!nextPath) continue
            if (j < path.length - 1) downloadZip = downloadZip.folder(nextPath) ?? zip
            else downloadZip = downloadZip.file(nextPath, download.blob)
        }
    })
    zip.generateAsync({ type: 'blob' }).then((zipFile) => {
        const currentDate = new Date().getTime()
        const zipFileName = `LedgeMed-${currentDate}.zip`
        return FileSaver.saveAs(zipFile, zipFileName)
    })
}

/* 
    https://huynvk.dev/blog/download-files-and-zip-them-in-your-browsers-using-javascript
    
    REVISIT Server side option were - but requires privileges for private docs in lambda
    
    https://dev.to/ldsrogan/aws-sdk-with-javascript-multi-files-download-from-s3-5118
    https://amiantos.net/zip-multiple-files-on-aws-s3/
 */
export async function downloadAndZip(
    context: ContextDataInterface,
    documents: Document[],
    documentTypes: DocumentType[]
): Promise<Error | undefined> {
    try {
        const okToProceed = await context.user.checkSessionActive()
        if (!okToProceed) return ErrorSessionLapsed

        await downloadDocumentsForZip(
            context,
            documents.filter((d) => d.uploaded),
            documentTypes
        ).then(exportZip)

        return undefined
    } catch (error) {
        const errorMessage = getErrorMessage('calling the api', error)
        console.log(errorMessage)
        return new Error(errorMessage)
    }
}

async function retrieveSingle(
    context: ContextDataInterface,
    document: Document
): Promise<[Error | undefined, DownLoad | undefined]> {
    try {
        const okToProceed = await context.user.checkSessionActive()
        if (!okToProceed) return [ErrorSessionLapsed, undefined]

        const download = await retrieveDocument(context, document, getFileName)
        if (!download.blob) return [new Error('There was an error dwnloading the file!'), undefined]
        //FileSaver.saveAs(fileData.blob, fileData.fileName);
        return [undefined, download]
    } catch (error) {
        const errorMessage = getErrorMessage('calling the api', error)
        console.log(errorMessage)
        return [new Error(errorMessage), undefined]
    }
}

export async function deleteSingle(context: ContextDataInterface, documentId: string): Promise<Error | undefined> {
    try {
        const okToProceed = await context.user.checkSessionActive()
        if (!okToProceed) return ErrorSessionLapsed
        const storageDetails = getStorageDetails(context.ledgeUser)
        const removeResult = await Storage.remove(`${storageDetails.folder}${documentId}`, {
            level: storageDetails.level,
        })
        if (!removeResult) {
            const errorMsg = 'Error deleting document: ' + documentId
            console.log(errorMsg)
            return new Error(errorMsg)
        }
        const deletedDocument = await callApi<Document>(context.user, 'deleteDocument', {
            query: deleteDocument,
            variables: { pk: documentId },
        })
        if (!deletedDocument.Result) {
            const errorMsg = getErrorMessage('Error deleting document record: ', deletedDocument.Error)
            console.log(errorMsg)
            return new Error(errorMsg)
        }
        return undefined
    } catch (error) {
        const errorMsg = getErrorMessage('Error deleting document record: ', error)
        console.log(errorMsg)
        return new Error(errorMsg)
    }
}

export async function deleteMany(context: ContextDataInterface, documentIds: [string]): Promise<Error | undefined> {
    const okToProceed = await context.user.checkSessionActive()
    if (!okToProceed) return ErrorSessionLapsed

    const results = await Promise.map<string, Error | undefined>(
        documentIds,
        async (documentId: string) => {
            return deleteSingle(context, documentId)
        },
        { concurrency: 4 }
    )
    return results.find((r) => r !== undefined)
}

export async function getPresignedUrl(
    context: ContextDataInterface,
    document: Document
): Promise<[Error | undefined, string, string | undefined]> {
    try {
        const fileName = getFileName(document)
        const okToProceed = await context.user.checkSessionActive()
        if (!okToProceed) return [ErrorSessionLapsed, fileName, undefined]
        const storageDetails = getStorageDetails(context.ledgeUser)
        const signedURL = await Storage.get(`${storageDetails.folder}${document.id}`, {
            level: storageDetails.level,
            contentType: `${document.contentType}`,
            expires: 180,
            //contentDisposition: "inline"
        })
        return [undefined, fileName, signedURL]
    } catch (error) {
        const errorMessage = `Unable to view document! ${error}`
        return [new Error(errorMessage), '', undefined]
    }
}

export async function userDocumentExists(
    context: ContextDataInterface,
    document: Document
): Promise<[Error | undefined, boolean]> {
    try {
        const okToProceed = await context.user.checkSessionActive()
        if (!okToProceed) return [ErrorSessionLapsed, false]
        const storageDetails = getStorageDetails(context.ledgeUser)
        const existing = await Storage.list(`${storageDetails.folder}${document.id}`, {
            level: storageDetails.level,
        })
        const exists = existing.length === 1
        return [undefined, exists]
    } catch (error) {
        const errorMessage = `Unable to view document! ${error}`
        return [new Error(errorMessage), false]
    }
}

// function showDownload(download: DownLoad) {
//     const file = new File([download.blob], download.fileName, {
//         type: download.contentType,
//     });
//     const url = window.URL.createObjectURL(file);
//     window.open(url, download.fileName);
//     // var link = document.createElement("a");
//     // link.href = url;
//     // link.target= "_new";
//     // //link.download = download.fileName;
//     // link.click();
//     // setTimeout(function () {
//     //     // For Firefox it is necessary to delay revoking the ObjectURL
//     //     window.URL.revokeObjectURL(data);
//     // }, 100);
// }

export async function viewDocument(context: ContextDataInterface, document: Document): Promise<Error | undefined> {
    try {
        const [error, filename, signedUrl] = await getPresignedUrl(context, document)
        if (error) return error
        window.open(signedUrl, filename)
        return undefined
        // const [result, download] = await downloadSingle(user, document);
        // if (result.error) {
        //     return new Error(result.error);
        // }
        // showDownload(download!);
    } catch (error) {
        return new Error(getErrorMessage('openDocumentInNewTab', error))
    }
}

export async function downloadDocument(context: ContextDataInterface, document: Document): Promise<Error | undefined> {
    try {
        const [error, download] = await retrieveSingle(context, document)
        if (error) return error
        const fileName = getFileName(document)
        FileSaver.saveAs(download?.blob, fileName)
        return undefined
    } catch (error) {
        return new Error(getErrorMessage('openDocumentInNewTab', error))
    }
}
