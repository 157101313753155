import { useState, useContext, useEffect, useRef } from 'react'
import { useParams, Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Backdrop, Box, CircularProgress, Grid, Paper, Button, Typography, Chip } from '@mui/material'
import { updateContextForUser } from '../../common/apiUtils'
import { UserContext } from '../../common/userContext'
import { getErrorMessage } from '../../common/errorUtils'
import { PageHeader } from '../common/pageHeader'
import { ApplicationsGrid } from '../applications/applicationsGrid'
import { User } from '../../API'
import { getSpecialtyColor } from '../profile/profileCommon'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    //padding: theme.spacing(1),
    background: theme.palette.background.paper,
    padding: '3px 7px 3px 7px',
    // textAlign: 'center',
    height: '100%',
    borderRadius: 5,
    borderColor: 'F9F9F9',
    borderStyle: 'solid',
    borderWidth: '1px',
    color: theme.palette.text.secondary,
}))

const FieldName = styled(Typography)(({ theme }) => ({
    variant: 'caption',
    display: 'inline-list-item',
    color: theme.palette.text.disabled,
}))

export default function Candidate() {
    const { id } = useParams()
    const applicationsGridRef = useRef(null)
    const contextData = useContext(UserContext)
    const [user, setUser] = useState<User>()
    const [pageError, setPageError] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)
    const [backdropOpen, setBackdropOpen] = useState<boolean>(true)

    useEffect(() => {
        const initialiseCandidate = async () => {
            if (!id) {
                setBackdropOpen(false)
                setPageError(`no users id provided`)
                return
            }
            const result = await updateContextForUser(contextData, id)
            if (result.Error) {
                setBackdropOpen(false)
                setPageError(getErrorMessage('retrieving the user', result.Error))
                return
            }
            setUser(result.Result![0])
            setBackdropOpen(false)
        }
        if (loading) {
            setLoading(false)
            initialiseCandidate()
        }
    }, [contextData, id, loading])

    if (contextData.user.isAdminOrRecruiter() === false) return <div />

    if (!id || !user) return <div />

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                width: '100%',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                }}
            >
                <PageHeader title={`${user.firstName}\u00a0${user.lastName}`} />

                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', mr: 1 }}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            const handle = applicationsGridRef.current
                            if (handle === null) return
                            ;(handle as any).addChecklist()
                        }}
                        sx={{ mr: 1 }}
                    >
                        Add Checklist
                    </Button>
                    <Button variant="outlined" component={Link} to="/documents">
                        Manage Documents
                    </Button>
                </Box>
            </Box>

            <br />

            <Box
                sx={{
                    ml: 2,
                    mr: 2,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={3} sx={{ padding: '3px', background: 'background.paper' }}>
                        <Item>
                            <Box sx={{ color: '#014F41', fontWeight: 'bold' }}>General Details</Box>
                            <Box component="ul" sx={{ pl: 2, listStyleType: 'none' }}>
                                <li>
                                    <FieldName>DOB</FieldName> {user.dateOfBirth ?? 'not entered'}
                                </li>
                                <li>
                                    <FieldName>Email</FieldName> {user.email ?? ''}
                                </li>
                                <li>
                                    <FieldName>Phone</FieldName> {user.phone ?? ''}
                                </li>
                            </Box>
                        </Item>
                    </Grid>

                    <Grid item xs={6} lg={3} sx={{ padding: '3px', background: 'background.paper' }}>
                        <Item>
                            <Box sx={{ color: '#014F41', fontWeight: 'bold' }}>Experience</Box>
                            <Box component="ul" sx={{ pl: 2, listStyleType: 'none' }}>
                                <li>
                                    <FieldName>Years Experience</FieldName> {user.yearsOfExperience ?? 'not entered'}
                                </li>
                                <li>
                                    <FieldName>Level</FieldName> {user.level ?? 'not entered'}
                                </li>
                                <li>
                                    <FieldName>Current Job Title</FieldName> {user.currentJobTitle ?? 'not entered'}
                                </li>
                                <li>
                                    <FieldName>Current Job Type</FieldName> {user.currentJobType ?? 'not entered'}
                                </li>
                            </Box>
                        </Item>
                    </Grid>
                    <Grid item xs={6} lg={3} sx={{ padding: '3px', background: 'background.paper' }}>
                        <Item>
                            <Box sx={{ color: '#014F41', fontWeight: 'bold' }}>Membership Information</Box>
                            <Box component="ul" sx={{ pl: 2, listStyleType: 'none' }}>
                                <li>
                                    <FieldName>WWCC</FieldName> {user.wwccNumber ?? 'not entered'}
                                </li>
                                <li>
                                    <FieldName>AHPRA</FieldName> {user.ahpraNumber ?? 'not entered'}
                                </li>
                                <li>
                                    <FieldName>Prescriber</FieldName> {user.prescriberNumber ?? 'not entered'}
                                </li>
                                <li>
                                    <FieldName>Citizenship</FieldName> {user.citizenshipStatus ?? 'not entered'}
                                </li>
                            </Box>
                        </Item>
                    </Grid>
                    <Grid item xs={6} lg={3} sx={{ padding: '3px', background: 'background.paper' }}>
                        <Item>
                            <Box sx={{ color: '#014F41', fontWeight: 'bold' }}>Interests</Box>
                            <Box component="ul" sx={{ pl: 2, listStyleType: 'none' }}>
                                <li>
                                    <FieldName>Seeking</FieldName> {user.seekingLevels ?? 'not entered'}
                                </li>
                                <li>
                                    <FieldName>Specialty Fields</FieldName>
                                    <br />
                                    <Box sx={{ mt: 1 }}>
                                        {user.seekingSpecialities &&
                                            user.seekingSpecialities.map((s, i) => (
                                                <Chip
                                                    key={`speciality_${i}`}
                                                    size="small"
                                                    sx={{ background: getSpecialtyColor(s) }}
                                                    label={s}
                                                />
                                            ))}
                                    </Box>
                                </li>
                            </Box>
                        </Item>
                    </Grid>
                </Grid>
            </Box>

            <br />

            <ApplicationsGrid ref={applicationsGridRef} />

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}
