import { useState, useContext, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import {
    useGridApiRef,
    DataGridPro,
    GridToolbarContainer,
    GridActionsCellItem,
    GridFilterModel,
    GridLinkOperator,
    GridRowParams,
    GridCallbackDetails,
} from '@mui/x-data-grid-pro'
import { GridToolbarSearch } from '../common/gridToolbarSearch'
import { UserContext } from '../../common/userContext'
import { callApi, clearCandidateContext } from '../../common/apiUtils'
import { ConfirmDialog, ConfirmDialogProps, ConfirmDialogPropsClosed } from '../common/confirmDialog'
import { PageHeader } from '../common/pageHeader'
import { User } from '../../API'

export const getUsersLedgeRec = /* GraphQL */ `
    query GetUsersLedgeRec {
        getUsersLedgeRec {
            id
            firstName
            lastName
            phone
            email
            dateOfBirth
            level
            yearsOfExperience
        }
    }
`

// export const getUsers = /* GraphQL */ `
//     query GetUsers {
//         getUsers {
//             id
//             firstName
//             lastName
//             phone
//             email
//             dateOfBirth
//             level
//             yearsOfExperience
//         }
//     }
// `

export type EditToolbarProps = {
    search: (text: String) => void
}

function EditToolbar({ search }: EditToolbarProps) {
    return (
        <GridToolbarContainer>
            <GridToolbarSearch search={search} />
        </GridToolbarContainer>
    )
}

export default function Candidates() {
    const contextData = useContext(UserContext)
    const navigate = useNavigate()
    const apiRef = useGridApiRef()
    const filterText = useRef('')
    const [rows, setRows] = useState<User[]>([])
    const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(undefined)
    const [processing, setProcessing] = useState<boolean>(false)
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps>(ConfirmDialogPropsClosed)

    useEffect(() => {
        let isCancelled = false
        const getRows = async () => {
            await clearCandidateContext(contextData)
            return callApi<Array<User>>(contextData.user, 'getUsersLedgeRec', {
                query: getUsersLedgeRec,
            })
                .then((applications) => {
                    if (isCancelled) return
                    if (applications.Result) {
                        setRows(applications.Result)
                    }
                    setProcessing(false)
                })
                .catch((e) => {
                    setProcessing(false)
                })
        }
        getRows()
        return () => {
            isCancelled = true
        }
    }, [contextData.user])

    const search = (value: string) => {
        filterText.current = value
        applyFiltering()
    }

    const updateRow = (id: string) => (event: any) => {
        event.stopPropagation()
        navigate(`/candidate/${id}`)
    }

    const rowDoubleClick = (params: GridRowParams, event: any, details: GridCallbackDetails) => {
        event.defaultMuiPrevented = true
        navigate(`/candidate/${params.row.id}`)
    }

    const applyFiltering = () => {
        const value = filterText.current
        if (!value) {
            setFilterModel({ items: [] })
            return
        }
        var filterItems = [
            {
                id: 1,
                columnField: 'firstName',
                operatorValue: 'contains',
                value: value,
            },
        ]
        const filter: GridFilterModel = {
            items: filterItems,
            linkOperator: GridLinkOperator.Or,
        }
        setFilterModel(filter)
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            type: 'string',
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            flex: 1,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            flex: 1,
        },
        {
            field: 'level',
            headerName: 'Level',
            flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }: any) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={updateRow(id)}
                        color="inherit"
                    />,
                ]
            },
        },
    ]

    if (!contextData.user.isAdminOrRecruiter()) return <div />

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                minHeight: '300px',
                minWidth: '300px',
            }}
        >
            <ConfirmDialog {...confirmDialogProps} />
            <PageHeader title="Candidates" />

            <DataGridPro
                autoPageSize
                pagination
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                loading={processing}
                filterModel={filterModel}
                onRowDoubleClick={rowDoubleClick}
                editMode="row"
                components={{
                    Toolbar: EditToolbar,
                }}
                componentsProps={{
                    toolbar: { search },
                }}
                initialState={{
                    sorting: {
                        sortModel: [
                            { field: 'firstName', sort: 'asc' },
                            { field: 'lastName', sort: 'asc' },
                        ],
                    },
                }}
            />
        </Box>
    )
}
