import { useState, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { Backdrop, Box, Button, CircularProgress, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
//import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import AddIcon from '@mui/icons-material/Add'
import DownloadIcon from '@mui/icons-material/Download'
import {
    useGridApiRef,
    DataGridPro,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowParams,
    GridCallbackDetails,
    GridRowId,
    GridFilterOperator,
    GridFilterItem,
    GridColDef,
    GridCellParams,
    GridFilterModel,
    GridLinkOperator,
    GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import { GridToolbarSearch } from '../common/gridToolbarSearch'
import { DocumentTypeLabel } from '../common/documentTypeLabel'
import { EditResult, EditProps, EditPropsClosed } from '../../common/types'
import { ConfirmDialog, ConfirmDialogProps, ConfirmDialogPropsClosed } from '../common/confirmDialog'
import { DocumentEditDialog } from '../documents/documentEdit'
import { UserContext } from '../../common/userContext'
import { Document } from '../../API'
import { downloadAndZip, deleteSingle, deleteMany, viewDocument } from '../../common/documentUtils'
import { PageInfo } from '../common/pageInfo'
import * as utils from '../../common/typeUtils'

type EditToolbarProps = {
    addRow: () => void
    deleteRows: () => void
    download: () => void
    toggleSelection: () => void
    search: (text: String) => void
}

function EditToolbar({ addRow, deleteRows, download, search }: EditToolbarProps) {
    const handleAddClick = async () => {
        addRow()
    }

    const handleDownloadClick = () => {
        download()
    }

    const handleDeleteClick = () => {
        deleteRows()
    }

    return (
        <GridToolbarContainer>
            <GridToolbarSearch search={search} />
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddClick}
                //sx={{padding: "3px 7px 3px 7px"}}
            >
                UPLOAD DOCUMENT
            </Button>
            <Button
                //color="primary"
                startIcon={<DownloadIcon />}
                onClick={handleDownloadClick}
            >
                Download Selected
            </Button>
            <Button
                //color="primary"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteClick}
            >
                Delete Selected
            </Button>
            {/* <GridToolbarColumnsButton />
      <GridToolbarFilterButton /> */}
        </GridToolbarContainer>
    )
}

EditToolbar.propTypes = {
    // apiRef: PropTypes.shape({
    //   current: PropTypes.object.isRequired,
    // }).isRequired,
    addRow: PropTypes.func.isRequired,
    deleteRows: PropTypes.func.isRequired,
    download: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
}

export function DocumentsGridView() {
    const contextData = useContext(UserContext)
    const apiRef = useGridApiRef()
    const filterText = useRef('')
    const [pageError, setPageError] = useState<string | undefined>()
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>()
    const [processing, setProcessing] = useState<boolean>(false)
    const [backdropOpen, setBackdropOpen] = useState<boolean>(false)
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps>(ConfirmDialogPropsClosed)
    const [editDialogProps, setEditDialogProps] = useState<EditProps>(EditPropsClosed)

    const addRow = () => {
        let editProps: EditProps = {
            open: true,
            id: 'add',
            data: undefined,
            callback: handleEditDialogResult,
            handleClose: (e) => setEditDialogProps(EditPropsClosed),
        }
        setEditDialogProps(editProps)
    }

    const download = async () => {
        try {
            var rowsToDownload = rowsSelected.map((id) => apiRef.current.getRow(id) as Document)
            if (rowsToDownload.length === 0) return
            setBackdropOpen(true)
            const error = await downloadAndZip(contextData, rowsToDownload, contextData.documentTypes)
            setPageError(error?.message)
        } catch (error) {
            console.log(error)
        } finally {
            setBackdropOpen(false)
        }
    }

    const updateRow = (id: string) => (event: any) => {
        event.stopPropagation()
        let editProps: EditProps = {
            open: true,
            id: id,
            data: undefined,
            callback: handleEditDialogResult,
            handleClose: (e) => setEditDialogProps(EditPropsClosed),
        }
        setEditDialogProps(editProps)
    }

    const deleteRow = (id: string) => (event: any) => {
        event.stopPropagation()
        const row = apiRef.current.getRow(id)
        if (!row) {
            console.log(`Edit row for ${id} was null!`)
            return
        }
        let props: ConfirmDialogProps = {
            open: true,
            title: 'Delete Document',
            description: `Are you sure that you would like to delete the document ${row.documentName}?`,
            action: 'Delete',
            data: id,
            callback: handleDeleteRow,
        }
        setConfirmDialogProps(props)
    }

    const deleteRows = () => {
        var rowsToDelete = rowsSelected.map((id) => id.toString())
        if (rowsToDelete.length === 0) return
        let props: ConfirmDialogProps = {
            open: true,
            title: 'Delete Documents',
            description: `Are you sure that you would like to delete ${rowsToDelete.length} document(s)?`,
            action: 'Delete',
            data: rowsToDelete,
            callback: handleDeleteRows,
        }
        setConfirmDialogProps(props)
    }

    const getRowClassName = (params: any) => {
        const isExpired = utils.hasExpired(params.row.expires)
        return isExpired ? 'super-app-theme--expired' : ''
    }

    const handleEditDialogResult = (editResult: EditResult, document: any) => {
        setEditDialogProps(EditPropsClosed)
        switch (editResult) {
            case EditResult.Added:
                if (document) {
                    contextData.setDocuments([...contextData.documents, document])
                }
                break
            case EditResult.Updated:
                if (document) {
                    const except = contextData.documents.filter((r) => r.id !== document.id)
                    contextData.setDocuments([...except, document])
                }
                break
            default:
                break
        }
    }

    const handleDeleteRow = async (action: string, data: any, result: boolean) => {
        setConfirmDialogProps(ConfirmDialogPropsClosed)

        if (!result || action !== 'Delete') return

        const id = data as string
        if (!id) {
            console.log('Delete row unexpected data!')
            return
        }

        try {
            setProcessing(true)
            const error = await deleteSingle(contextData, id)
            if (error) {
                setPageError(error.message)
                return
            }
            const except = contextData.documents.filter((r) => r.id !== id)
            contextData.setDocuments(except)
        } catch (error) {
            console.log(error)
        } finally {
            setProcessing(false)
        }
    }

    const handleDeleteRows = async (action: string, data: any, result: boolean) => {
        setConfirmDialogProps(ConfirmDialogPropsClosed)

        if (!result || action !== 'Delete') return

        const toDelete = data as [string]
        if (!toDelete) {
            console.log('Delete rows unexpected data!')
            return
        }

        try {
            setProcessing(true)
            const error = await deleteMany(contextData, toDelete)
            if (error) {
                setPageError(error.message)
                return
            }
            const except = contextData.documents.filter((r) => toDelete.includes(r.id) === false)
            contextData.setDocuments(except)
        } catch (error) {
            console.log(error)
        } finally {
            setProcessing(false)
        }
    }

    const handleRowDoubleClick = (params: GridRowParams, event: any, details: GridCallbackDetails) => {
        event.defaultMuiPrevented = true
        openDocumentInNewTab(params.row.id)
    }

    const viewRow = (id: string) => async (event: any) => {
        event.stopPropagation()
        openDocumentInNewTab(id)
    }

    const openDocumentInNewTab = async (id: string) => {
        const row = apiRef.current.getRow(id) as Document
        if (!row) {
            console.log(`Edit row for ${id} was null!`)
            return
        }

        try {
            setBackdropOpen(true)
            const error = await viewDocument(contextData, row)
            if (error) {
                setPageError(error.message)
                return
            }
        } catch (error) {
            alert(`Unable to view document! ${error}`)
        } finally {
            setBackdropOpen(false)
        }
    }

    const selectedOperator: GridFilterOperator = {
        label: 'Selected',
        value: 'selected',
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null
            }

            return (params: GridCellParams): boolean => {
                const id = params.value as string
                return rowsSelected.find((selected) => selected === id) !== undefined
            }
        },
        InputComponentProps: { type: 'string' },
    }

    const documentTypeContainsOperator: GridFilterOperator = {
        label: 'DocumentTypeContains',
        value: 'documentTypeContains',
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null
            }

            return (params: GridCellParams): boolean => {
                // TODO REVISIT performance?
                const documentType = contextData.documentTypes.find((d) => d.id === params.value)
                const pathName = documentType ? utils.formatPathName(documentType.pathName) : ''
                // Case insensitive
                return pathName.toUpperCase().includes(filterItem.value?.toUpperCase() ?? '')
            }
        },
        InputComponentProps: { type: 'string' },
    }

    const search = (value: string) => {
        filterText.current = value
        applyFiltering()
    }

    const applyFiltering = () => {
        const value = filterText.current
        if (!value) {
            setFilterModel({ items: [] })
            return
        }
        var filterItems = [
            {
                id: 1,
                columnField: 'documentName',
                operatorValue: 'contains',
                value: value,
            },
            {
                id: 2,
                columnField: 'documentTypeId',
                operatorValue: 'documentTypeContains',
                value: value,
            },
            // {
            //     id: 3,
            //     columnField: "id",
            //     operatorValue: "selected",
            //     value: value,
            // },
        ]
        const filter: GridFilterModel = {
            items: filterItems,
            linkOperator: GridLinkOperator.Or,
        }
        setFilterModel(filter)
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            type: 'string',
            filterOperators: [selectedOperator],
        },
        {
            field: 'documentTypeId',
            headerName: 'Document Type',
            flex: 1,
            resizable: true,
            filterOperators: [documentTypeContainsOperator],
            renderCell: (params: GridRenderCellParams<string>) => <DocumentTypeLabel id={params.row.documentTypeId} />,
        },
        {
            field: 'documentName',
            headerName: 'Document Name',
            flex: 1,
            resizable: true,
        },
        {
            field: 'expires',
            headerName: 'Expires',
            //flex: 0.5,
            width: 150,
            resizable: false,
            type: 'date',
        },
        {
            field: 'uploaded',
            headerName: 'Uploaded',
            //flex: 0.5,
            width: 100,
            resizable: false,
            type: 'boolean',
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            //flex: 0.5,
            width: 100,
            resizable: false,
            cellClassName: 'actions',
            getActions: ({ id }: any) => {
                const row = apiRef.current.getRow(id)
                const documentMissing = row?.uploaded === false ?? false

                return [
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Edit">
                                <EditIcon />
                            </Tooltip>
                        }
                        label="Edit"
                        className="textPrimary"
                        onClick={updateRow(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Delete">
                                <DeleteIcon />
                            </Tooltip>
                        }
                        label="Delete"
                        onClick={deleteRow(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="View">
                                <VisibilityIcon />
                            </Tooltip>
                        }
                        label="View"
                        onClick={viewRow(id)}
                        color="inherit"
                        disabled={documentMissing}
                        //readOnly={documentMissing}
                    />,
                ]
            },
        },
    ]

    return (
        <Box
            sx={{
                display: 'flex-column',
                justifyContent: 'flex-end',
                height: '100%',
                width: '100%',
                minHeight: '300px',
                minWidth: '300px',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                '& .super-app-theme--expired': {
                    bgcolor: 'mistyrose',
                },
            }}
        >
            <ConfirmDialog {...confirmDialogProps} />
            <DocumentEditDialog {...editDialogProps} />
            <PageInfo message={pageError} color="error" />
            <DataGridPro
                autoPageSize
                pagination
                disableColumnReorder={true}
                rows={contextData.documents}
                columns={columns}
                apiRef={apiRef}
                loading={processing}
                checkboxSelection={true}
                disableSelectionOnClick
                onSelectionModelChange={(selected) => {
                    setRowsSelected(selected)
                    applyFiltering()
                }}
                selectionModel={rowsSelected}
                filterModel={filterModel}
                editMode="row"
                getRowClassName={(params) => `${getRowClassName(params)}`}
                onRowDoubleClick={handleRowDoubleClick}
                components={{
                    Toolbar: EditToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        addRow,
                        deleteRows,
                        download,
                        search,
                    },
                }}
                initialState={{
                    //pinnedColumns: { right: ["actions"] },
                    sorting: {
                        sortModel: [
                            { field: 'documentType', sort: 'asc' },
                            { field: 'documentName', sort: 'asc' },
                        ],
                    },
                }}
            />
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}
