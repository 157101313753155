/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type UserInput = {
  id: string,
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  dateOfBirth?: string | null,
  level?: QualificationLevel | null,
  yearsOfExperience?: number | null,
  currentJobTitle?: string | null,
  currentJobType?: PositionType | null,
  seekingLevels?: Array< QualificationLevel > | null,
  seekingSpecialities?: Array< string > | null,
  bankName?: string | null,
  bankBsb?: string | null,
  bankAccountNumber?: string | null,
  superannuationNumber?: string | null,
  taxFileNumber?: string | null,
  abnNumber?: string | null,
  wwccNumber?: string | null,
  wwccExpiry?: string | null,
  ahpraNumber?: string | null,
  prescriberNumber?: string | null,
  citizenshipStatus?: CitizenshipStatus | null,
  activeCampaignContactId?: string | null,
  activeCampaignPendingUpdate?: boolean | null,
};

export enum QualificationLevel {
  Student = "Student",
  Intern = "Intern",
  Resident = "Resident",
  UnaccreditedRegistrar = "UnaccreditedRegistrar",
  Registrar = "Registrar",
  Consultant = "Consultant",
}


export enum PositionType {
  Temporary = "Temporary",
  Permanent = "Permanent",
  Locum = "Locum",
}


export enum CitizenshipStatus {
  AustralianCitizen = "AustralianCitizen",
  PermanentResident = "PermanentResident",
}


export type User = {
  __typename: "User",
  id: string,
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  dateOfBirth?: string | null,
  ledgeRecUser?: string | null,
  level?: QualificationLevel | null,
  yearsOfExperience?: number | null,
  currentJobTitle?: string | null,
  currentJobType?: PositionType | null,
  seekingLevels?: Array< QualificationLevel > | null,
  seekingSpecialities?: Array< string > | null,
  bankName?: string | null,
  bankBsb?: string | null,
  bankAccountNumber?: string | null,
  superannuationNumber?: string | null,
  taxFileNumber?: string | null,
  abnNumber?: string | null,
  wwccNumber?: string | null,
  wwccExpiry?: string | null,
  ahpraNumber?: string | null,
  prescriberNumber?: string | null,
  citizenshipStatus?: CitizenshipStatus | null,
  activeCampaignContactId?: string | null,
  activeCampaignPendingUpdate?: boolean | null,
  created: number,
  updated: number,
  documents:  Array<Document >,
};

export type Document = {
  __typename: "Document",
  id: string,
  userId: string,
  documentName: string,
  documentTypeId: string,
  uploaded: boolean,
  originalFileName: string,
  contentType: string,
  expires?: string | null,
  details?: string | null,
  updated: number,
  user: User,
  documentType?: DocumentType | null,
};

export type DocumentType = {
  __typename: "DocumentType",
  id: string,
  pathName: string,
  hasMany: boolean,
  applicationSpecific?: boolean | null,
  info?: string | null,
  url?: string | null,
};

export type UserLedgeRecInput = {
  id: string,
  ledgeRecUser: boolean,
};

export type UserAuth0Input = {
  id: string,
  useMfa: boolean,
  resetMfa: boolean,
};

export type UserAuth0 = {
  __typename: "UserAuth0",
  id: string,
  useMfa: boolean,
};

export type DocumentTypeInput = {
  id: string,
  pathName: string,
  hasMany: boolean,
  applicationSpecific?: boolean | null,
  info?: string | null,
  url?: string | null,
};

export type DocumentInput = {
  id: string,
  userId: string,
  documentName: string,
  documentTypeId: string,
  uploaded: boolean,
  originalFileName: string,
  contentType: string,
  expires?: string | null,
  details?: string | null,
};

export type ApplicationInput = {
  id: string,
  userId: string,
  name: string,
  templateId?: string | null,
  templateVersion?: number | null,
  templateApplied?: boolean | null,
  applicationSections: Array< ApplicationSectionInput >,
};

export type ApplicationSectionInput = {
  isNew: boolean,
  id: string,
  applicationId: string,
  name: string,
  applicationDocuments: Array< ApplicationDocumentInput >,
};

export type ApplicationDocumentInput = {
  isNew: boolean,
  id: string,
  applicationSectionId: string,
  documentTypeId: string,
  documentId?: string | null,
};

export type Application = {
  __typename: "Application",
  id: string,
  name: string,
  userId: string,
  templateId?: string | null,
  templateVersion?: number | null,
  templateApplied?: boolean | null,
  created: number,
  updated: number,
  user?: User | null,
  applicationSections:  Array<ApplicationSection >,
};

export type ApplicationSection = {
  __typename: "ApplicationSection",
  id: string,
  applicationId: string,
  name: string,
  created: number,
  updated: number,
  application?: Application | null,
  applicationDocuments:  Array<ApplicationDocument >,
};

export type ApplicationDocument = {
  __typename: "ApplicationDocument",
  id: string,
  applicationSectionId: string,
  documentTypeId: string,
  documentId?: string | null,
  created: number,
  updated: number,
  applicationSection?: ApplicationSection | null,
  documentType?: DocumentType | null,
  document?: Document | null,
};

export type CreateUserMutationVariables = {
  item: UserInput,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    dateOfBirth?: string | null,
    ledgeRecUser?: string | null,
    level?: QualificationLevel | null,
    yearsOfExperience?: number | null,
    currentJobTitle?: string | null,
    currentJobType?: PositionType | null,
    seekingLevels?: Array< QualificationLevel > | null,
    seekingSpecialities?: Array< string > | null,
    bankName?: string | null,
    bankBsb?: string | null,
    bankAccountNumber?: string | null,
    superannuationNumber?: string | null,
    taxFileNumber?: string | null,
    abnNumber?: string | null,
    wwccNumber?: string | null,
    wwccExpiry?: string | null,
    ahpraNumber?: string | null,
    prescriberNumber?: string | null,
    citizenshipStatus?: CitizenshipStatus | null,
    activeCampaignContactId?: string | null,
    activeCampaignPendingUpdate?: boolean | null,
    created: number,
    updated: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  item: UserInput,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    dateOfBirth?: string | null,
    ledgeRecUser?: string | null,
    level?: QualificationLevel | null,
    yearsOfExperience?: number | null,
    currentJobTitle?: string | null,
    currentJobType?: PositionType | null,
    seekingLevels?: Array< QualificationLevel > | null,
    seekingSpecialities?: Array< string > | null,
    bankName?: string | null,
    bankBsb?: string | null,
    bankAccountNumber?: string | null,
    superannuationNumber?: string | null,
    taxFileNumber?: string | null,
    abnNumber?: string | null,
    wwccNumber?: string | null,
    wwccExpiry?: string | null,
    ahpraNumber?: string | null,
    prescriberNumber?: string | null,
    citizenshipStatus?: CitizenshipStatus | null,
    activeCampaignContactId?: string | null,
    activeCampaignPendingUpdate?: boolean | null,
    created: number,
    updated: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  pk: string,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    dateOfBirth?: string | null,
    ledgeRecUser?: string | null,
    level?: QualificationLevel | null,
    yearsOfExperience?: number | null,
    currentJobTitle?: string | null,
    currentJobType?: PositionType | null,
    seekingLevels?: Array< QualificationLevel > | null,
    seekingSpecialities?: Array< string > | null,
    bankName?: string | null,
    bankBsb?: string | null,
    bankAccountNumber?: string | null,
    superannuationNumber?: string | null,
    taxFileNumber?: string | null,
    abnNumber?: string | null,
    wwccNumber?: string | null,
    wwccExpiry?: string | null,
    ahpraNumber?: string | null,
    prescriberNumber?: string | null,
    citizenshipStatus?: CitizenshipStatus | null,
    activeCampaignContactId?: string | null,
    activeCampaignPendingUpdate?: boolean | null,
    created: number,
    updated: number,
  } | null,
};

export type UpdateUserLedgeRecMutationVariables = {
  item: UserLedgeRecInput,
};

export type UpdateUserLedgeRecMutation = {
  updateUserLedgeRec?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    dateOfBirth?: string | null,
    ledgeRecUser?: string | null,
    level?: QualificationLevel | null,
    yearsOfExperience?: number | null,
    currentJobTitle?: string | null,
    currentJobType?: PositionType | null,
    seekingLevels?: Array< QualificationLevel > | null,
    seekingSpecialities?: Array< string > | null,
    bankName?: string | null,
    bankBsb?: string | null,
    bankAccountNumber?: string | null,
    superannuationNumber?: string | null,
    taxFileNumber?: string | null,
    abnNumber?: string | null,
    wwccNumber?: string | null,
    wwccExpiry?: string | null,
    ahpraNumber?: string | null,
    prescriberNumber?: string | null,
    citizenshipStatus?: CitizenshipStatus | null,
    activeCampaignContactId?: string | null,
    activeCampaignPendingUpdate?: boolean | null,
    created: number,
    updated: number,
  } | null,
};

export type UpdateUserAuth0MutationVariables = {
  item: UserAuth0Input,
};

export type UpdateUserAuth0Mutation = {
  updateUserAuth0?:  {
    __typename: "UserAuth0",
    id: string,
    useMfa: boolean,
  } | null,
};

export type ResendUserAuth0EmailVerificationMutationVariables = {
  pk: string,
};

export type ResendUserAuth0EmailVerificationMutation = {
  resendUserAuth0EmailVerification?: boolean | null,
};

export type CreateDocumentTypeMutationVariables = {
  item: DocumentTypeInput,
};

export type CreateDocumentTypeMutation = {
  createDocumentType?:  {
    __typename: "DocumentType",
    id: string,
    pathName: string,
    hasMany: boolean,
    applicationSpecific?: boolean | null,
    info?: string | null,
    url?: string | null,
  } | null,
};

export type UpdateDocumentTypeMutationVariables = {
  item: DocumentTypeInput,
};

export type UpdateDocumentTypeMutation = {
  updateDocumentType?:  {
    __typename: "DocumentType",
    id: string,
    pathName: string,
    hasMany: boolean,
    applicationSpecific?: boolean | null,
    info?: string | null,
    url?: string | null,
  } | null,
};

export type DeleteDocumentTypeMutationVariables = {
  pk: string,
};

export type DeleteDocumentTypeMutation = {
  deleteDocumentType?:  {
    __typename: "DocumentType",
    id: string,
    pathName: string,
    hasMany: boolean,
    applicationSpecific?: boolean | null,
    info?: string | null,
    url?: string | null,
  } | null,
};

export type CreateDocumentMutationVariables = {
  item: DocumentInput,
};

export type CreateDocumentMutation = {
  createDocument?:  {
    __typename: "Document",
    id: string,
    userId: string,
    documentName: string,
    documentTypeId: string,
    uploaded: boolean,
    originalFileName: string,
    contentType: string,
    expires?: string | null,
    details?: string | null,
    updated: number,
  } | null,
};

export type UpdateDocumentMutationVariables = {
  item: DocumentInput,
};

export type UpdateDocumentMutation = {
  updateDocument?:  {
    __typename: "Document",
    id: string,
    userId: string,
    documentName: string,
    documentTypeId: string,
    uploaded: boolean,
    originalFileName: string,
    contentType: string,
    expires?: string | null,
    details?: string | null,
    updated: number,
  } | null,
};

export type DeleteDocumentMutationVariables = {
  pk: string,
};

export type DeleteDocumentMutation = {
  deleteDocument?:  {
    __typename: "Document",
    id: string,
    userId: string,
    documentName: string,
    documentTypeId: string,
    uploaded: boolean,
    originalFileName: string,
    contentType: string,
    expires?: string | null,
    details?: string | null,
    updated: number,
  } | null,
};

export type CreateApplicationMutationVariables = {
  item: ApplicationInput,
};

export type CreateApplicationMutation = {
  createApplication?:  {
    __typename: "Application",
    id: string,
    name: string,
    userId: string,
    templateId?: string | null,
    templateVersion?: number | null,
    templateApplied?: boolean | null,
    created: number,
    updated: number,
  } | null,
};

export type UpdateApplicationMutationVariables = {
  item: ApplicationInput,
};

export type UpdateApplicationMutation = {
  updateApplication?:  {
    __typename: "Application",
    id: string,
    name: string,
    userId: string,
    templateId?: string | null,
    templateVersion?: number | null,
    templateApplied?: boolean | null,
    created: number,
    updated: number,
  } | null,
};

export type DeleteApplicationMutationVariables = {
  pk: string,
};

export type DeleteApplicationMutation = {
  deleteApplication?:  {
    __typename: "Application",
    id: string,
    name: string,
    userId: string,
    templateId?: string | null,
    templateVersion?: number | null,
    templateApplied?: boolean | null,
    created: number,
    updated: number,
  } | null,
};

export type CreateApplicationTemplateMutationVariables = {
  item: ApplicationInput,
};

export type CreateApplicationTemplateMutation = {
  createApplicationTemplate?:  {
    __typename: "Application",
    id: string,
    name: string,
    userId: string,
    templateId?: string | null,
    templateVersion?: number | null,
    templateApplied?: boolean | null,
    created: number,
    updated: number,
  } | null,
};

export type UpdateApplicationTemplateMutationVariables = {
  item: ApplicationInput,
};

export type UpdateApplicationTemplateMutation = {
  updateApplicationTemplate?:  {
    __typename: "Application",
    id: string,
    name: string,
    userId: string,
    templateId?: string | null,
    templateVersion?: number | null,
    templateApplied?: boolean | null,
    created: number,
    updated: number,
  } | null,
};

export type UpdateApplicationTemplateAppliedMutationVariables = {
  pk: string,
};

export type UpdateApplicationTemplateAppliedMutation = {
  updateApplicationTemplateApplied?:  {
    __typename: "Application",
    id: string,
    name: string,
    userId: string,
    templateId?: string | null,
    templateVersion?: number | null,
    templateApplied?: boolean | null,
    created: number,
    updated: number,
  } | null,
};

export type DeleteApplicationTemplateMutationVariables = {
  pk: string,
};

export type DeleteApplicationTemplateMutation = {
  deleteApplicationTemplate?:  {
    __typename: "Application",
    id: string,
    name: string,
    userId: string,
    templateId?: string | null,
    templateVersion?: number | null,
    templateApplied?: boolean | null,
    created: number,
    updated: number,
  } | null,
};

export type GetUsersQuery = {
  getUsers?:  Array< {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    dateOfBirth?: string | null,
    ledgeRecUser?: string | null,
    level?: QualificationLevel | null,
    yearsOfExperience?: number | null,
    currentJobTitle?: string | null,
    currentJobType?: PositionType | null,
    seekingLevels?: Array< QualificationLevel > | null,
    seekingSpecialities?: Array< string > | null,
    bankName?: string | null,
    bankBsb?: string | null,
    bankAccountNumber?: string | null,
    superannuationNumber?: string | null,
    taxFileNumber?: string | null,
    abnNumber?: string | null,
    wwccNumber?: string | null,
    wwccExpiry?: string | null,
    ahpraNumber?: string | null,
    prescriberNumber?: string | null,
    citizenshipStatus?: CitizenshipStatus | null,
    activeCampaignContactId?: string | null,
    activeCampaignPendingUpdate?: boolean | null,
    created: number,
    updated: number,
  } | null > | null,
};

export type GetUsersLedgeRecQuery = {
  getUsersLedgeRec?:  Array< {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    dateOfBirth?: string | null,
    ledgeRecUser?: string | null,
    level?: QualificationLevel | null,
    yearsOfExperience?: number | null,
    currentJobTitle?: string | null,
    currentJobType?: PositionType | null,
    seekingLevels?: Array< QualificationLevel > | null,
    seekingSpecialities?: Array< string > | null,
    bankName?: string | null,
    bankBsb?: string | null,
    bankAccountNumber?: string | null,
    superannuationNumber?: string | null,
    taxFileNumber?: string | null,
    abnNumber?: string | null,
    wwccNumber?: string | null,
    wwccExpiry?: string | null,
    ahpraNumber?: string | null,
    prescriberNumber?: string | null,
    citizenshipStatus?: CitizenshipStatus | null,
    activeCampaignContactId?: string | null,
    activeCampaignPendingUpdate?: boolean | null,
    created: number,
    updated: number,
  } | null > | null,
};

export type GetUserByIdQueryVariables = {
  pk: string,
};

export type GetUserByIdQuery = {
  getUserById?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    dateOfBirth?: string | null,
    ledgeRecUser?: string | null,
    level?: QualificationLevel | null,
    yearsOfExperience?: number | null,
    currentJobTitle?: string | null,
    currentJobType?: PositionType | null,
    seekingLevels?: Array< QualificationLevel > | null,
    seekingSpecialities?: Array< string > | null,
    bankName?: string | null,
    bankBsb?: string | null,
    bankAccountNumber?: string | null,
    superannuationNumber?: string | null,
    taxFileNumber?: string | null,
    abnNumber?: string | null,
    wwccNumber?: string | null,
    wwccExpiry?: string | null,
    ahpraNumber?: string | null,
    prescriberNumber?: string | null,
    citizenshipStatus?: CitizenshipStatus | null,
    activeCampaignContactId?: string | null,
    activeCampaignPendingUpdate?: boolean | null,
    created: number,
    updated: number,
  } | null,
};

export type GetUserAuth0ByIdQueryVariables = {
  pk: string,
};

export type GetUserAuth0ByIdQuery = {
  getUserAuth0ById?:  {
    __typename: "UserAuth0",
    id: string,
    useMfa: boolean,
  } | null,
};

export type GetDocumentTypesQuery = {
  getDocumentTypes?:  Array< {
    __typename: "DocumentType",
    id: string,
    pathName: string,
    hasMany: boolean,
    applicationSpecific?: boolean | null,
    info?: string | null,
    url?: string | null,
  } > | null,
};

export type GetDocumentTypeByIdQueryVariables = {
  pk: string,
};

export type GetDocumentTypeByIdQuery = {
  getDocumentTypeById?:  {
    __typename: "DocumentType",
    id: string,
    pathName: string,
    hasMany: boolean,
    applicationSpecific?: boolean | null,
    info?: string | null,
    url?: string | null,
  } | null,
};

export type GetDocumentsQuery = {
  getDocuments?:  Array< {
    __typename: "Document",
    id: string,
    userId: string,
    documentName: string,
    documentTypeId: string,
    uploaded: boolean,
    originalFileName: string,
    contentType: string,
    expires?: string | null,
    details?: string | null,
    updated: number,
  } > | null,
};

export type GetDocumentByIdQueryVariables = {
  pk: string,
};

export type GetDocumentByIdQuery = {
  getDocumentById?:  {
    __typename: "Document",
    id: string,
    userId: string,
    documentName: string,
    documentTypeId: string,
    uploaded: boolean,
    originalFileName: string,
    contentType: string,
    expires?: string | null,
    details?: string | null,
    updated: number,
  } | null,
};

export type GetDocumentsForUserQueryVariables = {
  pk: string,
};

export type GetDocumentsForUserQuery = {
  getDocumentsForUser?:  Array< {
    __typename: "Document",
    id: string,
    userId: string,
    documentName: string,
    documentTypeId: string,
    uploaded: boolean,
    originalFileName: string,
    contentType: string,
    expires?: string | null,
    details?: string | null,
    updated: number,
  } > | null,
};

export type GetApplicationTemplatesQuery = {
  getApplicationTemplates?:  Array< {
    __typename: "Application",
    id: string,
    name: string,
    userId: string,
    templateId?: string | null,
    templateVersion?: number | null,
    templateApplied?: boolean | null,
    created: number,
    updated: number,
  } | null > | null,
};

export type GetApplicationByIdQueryVariables = {
  pk: string,
};

export type GetApplicationByIdQuery = {
  getApplicationById?:  {
    __typename: "Application",
    id: string,
    name: string,
    userId: string,
    templateId?: string | null,
    templateVersion?: number | null,
    templateApplied?: boolean | null,
    created: number,
    updated: number,
  } | null,
};

export type GetApplicationsForUserQueryVariables = {
  pk: string,
};

export type GetApplicationsForUserQuery = {
  getApplicationsForUser?:  Array< {
    __typename: "Application",
    id: string,
    name: string,
    userId: string,
    templateId?: string | null,
    templateVersion?: number | null,
    templateApplied?: boolean | null,
    created: number,
    updated: number,
  } > | null,
};

export type GetApplicationsByTemplateIdQueryVariables = {
  pk: string,
};

export type GetApplicationsByTemplateIdQuery = {
  getApplicationsByTemplateId?:  Array< {
    __typename: "Application",
    id: string,
    name: string,
    userId: string,
    templateId?: string | null,
    templateVersion?: number | null,
    templateApplied?: boolean | null,
    created: number,
    updated: number,
  } > | null,
};

export type OnCreateDocumentTypeSubscription = {
  onCreateDocumentType?:  {
    __typename: "DocumentType",
    id: string,
    pathName: string,
    hasMany: boolean,
    applicationSpecific?: boolean | null,
    info?: string | null,
    url?: string | null,
  } | null,
};
