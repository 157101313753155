import { useState, useContext, useEffect } from 'react'
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    FormHelperText,
    FormLabel,
    CircularProgress,
    TextField,
    DialogActions,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import MDEditor from '@uiw/react-md-editor'
import auLocale from 'date-fns/locale/en-AU'
import { callApi } from '../../common/apiUtils'
import { isValidName, formatPathName } from '../../common/typeUtils'
import { EditResult, EditProps } from '../../common/types'
import { uploadDocument, removeExtension, userDocumentExists } from '../../common/documentUtils'
import { hasExisting } from './documentCommon'
import { UserContext, getDocumentTypeInfoFromContext, ContextDataInterface } from '../../common/userContext'
import { PageHeader } from '../common/pageHeader'
import { PageInfo } from '../common/pageInfo'
import { DragAndDrop } from '../common/dragAndDrop'
import { Document, DocumentInput, DocumentType } from '../../API'
import { createDocument, updateDocument } from '../../graphql/mutations'
import * as utils from '../../common/typeUtils'

const { v4: uuid } = require('uuid')

export function DocumentEdit(props: EditProps) {
    const id = props.id
    const isNew = id === 'add'
    const contextData = useContext(UserContext)
    const documentTypesSorted = [...contextData.documentTypes].sort((a, b) => {
        if (a.pathName.startsWith('/Other') || b.pathName.startsWith('/Other')) {
            if (a.pathName.startsWith('/Other') && b.pathName.startsWith('/Other'))
                return a.pathName.localeCompare(b.pathName)
            return a.pathName.startsWith('/Other') ? 1 : -1
        }
        return a.pathName.localeCompare(b.pathName)
    })

    const initialDocument: DocumentInput = {
        id: uuid(),
        userId: contextData.ledgeUser?.id ?? '', // Use ledgeUser for id to support recruiter mode
        documentName: '',
        documentTypeId: '',
        uploaded: false,
        originalFileName: '',
        contentType: '',
        expires: undefined,
        details: undefined,
    }

    const [documentInput, setDocumentInput] = useState<DocumentInput>(initialDocument)
    const [popoverAnchor, setPopoverAnchor] = useState<any>(null)
    const [fileUpload, setFileUpload] = useState<File | undefined>(undefined)
    const [hasFile, setHasFile] = useState(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [backdropOpen, setBackdropOpen] = useState<boolean>(true)
    const [expires, setExpires] = useState<boolean>(false)
    const [documentTypeInfo, setDocumentTypeInfo] = useState<string>()
    const [isDuplicate, setIsDuplicate] = useState<boolean>(false)
    const [pageError, setPageError] = useState<string | undefined>()

    const [errorData, setErrorData] = useState<any>({
        documentName: '',
        documentTypeId: '',
        originalFileName: '',
        expires: '',
    })

    useEffect(() => {
        const getDocument = async () => {
            if (isNew) {
                setBackdropOpen(false)
                return
            }
            const document = contextData.documents.find((d) => d.id === id)
            if (!document) {
                setBackdropOpen(false)
                console.log('Document not found: ' + id)
                return
            }
            const existingDocumentInput: DocumentInput = {
                id: document.id,
                userId: document.userId,
                documentName: document.documentName,
                documentTypeId: document.documentTypeId,
                uploaded: document.uploaded ?? true,
                originalFileName: document.originalFileName,
                contentType: document.contentType,
                expires: document.expires,
                details: document.details,
            }
            setDocumentInput(existingDocumentInput)
            setDocumentTypeInfo(getDocumentTypeInfoFromContext(contextData, document.documentTypeId))
            setExpires(document.expires !== null)
            var errorUpdate = errorData
            if (utils.hasExpired(document.expires))
                errorUpdate = {
                    ...errorUpdate,
                    expires: 'the date specified is in the past',
                }
            const [result, exists] = await userDocumentExists(contextData, document)
            setHasFile(exists)
            if (!isNew && !exists)
                errorUpdate = {
                    ...errorUpdate,
                    originalFileName: `file ${document.originalFileName} missing`,
                }
            setErrorData(errorUpdate)
            setBackdropOpen(false)
        }
        if (loading) {
            setLoading(false)
            getDocument()
        }
    }, [id, isNew, loading, documentInput, errorData, contextData])

    const setDocumentChanged = (selectedFile: File) => {
        const fileName = selectedFile.name
        const contentType = selectedFile.type
        setFileUpload(selectedFile)
        setDocumentInput({
            ...documentInput,
            documentName: documentInput.documentName ? documentInput.documentName : removeExtension(fileName),
            uploaded: true,
            originalFileName: fileName,
            contentType: contentType,
        })
    }

    const setValue = (name: string, value: string | undefined | null) => {
        setDocumentInput({
            ...documentInput,
            [name]: value,
        })
    }

    const documentNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDocumentInput({
            ...documentInput,
            documentName: event.target.value,
        })
    }

    const getDocumentTypeName = () => {
        const documentType = contextData.documentTypes.find((d) => d.id === documentInput.documentTypeId)
        return documentType ? utils.parsePathName(documentType.pathName).name : 'Missing'
    }

    const documentTypeChanged = (event: SelectChangeEvent<any>) => {
        const documentTypeId = event.target.value
        let documentName = documentInput.documentName
        if (isNew) {
            const documentType = contextData.documentTypes.find((d) => d.id === documentTypeId)
            if (!documentName && documentType && documentType.hasMany === false)
                documentName = utils.parsePathName(documentType.pathName).name
            else documentName = documentName
        }
        setDocumentInput({
            ...documentInput,
            documentName: documentName,
            documentTypeId: documentTypeId,
        })
        const info = getDocumentTypeInfoFromContext(contextData, documentTypeId)
        setDocumentTypeInfo(info)
    }

    const checkExpiryChanged = (dateString: string | null | undefined) => {
        if (!utils.hasExpired(dateString)) errorChanged('expires', '')
        else errorChanged('expires', 'the date specified is in the past')
    }

    const expiryChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExpires(event.target.checked)
        if (!event.target.checked) setValue('expires', undefined)
        else setValue('expires', utils.getNextYearISODate())
        errorChanged('expires', '')
    }

    const expiresChanged = (date: string | null) => {
        setValue('expires', date)
        checkExpiryChanged(date)
    }

    const errorChanged = (name: string, message: string) => {
        const currentValue = errorData[name]
        if (currentValue === message) return
        setErrorData({
            ...errorData,
            [name]: message,
        })
    }

    const handleCancel = () => {
        props.callback(EditResult.Cancelled, undefined)
    }

    const handleSave = async () => {
        handleSubmit(false)
    }

    const handleSaveDuplicate = () => {
        handleSubmit(true)
    }

    const handleDrop = (files: File[]) => {
        console.log(files)
        if (files.length === 0) return
        setDocumentChanged(files[0])
    }

    const handleSubmit = async (allowDuplicate: boolean) => {
        let issueCount = 0

        setPageError(undefined)

        const [duplicateName, duplicateType, documentType] = hasExisting(isNew, documentInput, contextData)

        setIsDuplicate(duplicateType)
        if (duplicateType) {
            const warningMessage = `You already have a ${getDocumentTypeName()} document. Are you sure that
                    you would like to add another one?`
            setPageError(warningMessage)
        }

        if (duplicateName) {
            const documentTypeName = documentType ? formatPathName(documentType.pathName) : 'Unknown'
            errorChanged(
                'documentName',
                `there is already a document of type ${documentTypeName} named ${documentInput.documentName}`
            )
            issueCount = issueCount + 1
        } else if (isValidName(documentInput.documentName)) {
            errorChanged('documentName', '')
        } else {
            errorChanged('documentName', 'required field')
            issueCount = issueCount + 1
        }

        if (documentInput.documentTypeId) {
            errorChanged('documentTypeId', '')
        } else {
            errorChanged('documentTypeId', 'required field')
            issueCount = issueCount + 1
        }

        if (hasFile || fileUpload !== undefined) {
            errorChanged('originalFileName', '')
        } else {
            errorChanged('originalFileName', 'please attach a file first')
            issueCount = issueCount + 1
        }

        if (duplicateType && !allowDuplicate) {
            issueCount = issueCount + 1
        }

        // if (!utils.hasExpired(documentInput.expires)) {
        //   errorChanged("expires", "");
        // } else {
        //   errorChanged("expires", "the date specified is in the past");
        //   issueCount = issueCount + 1;
        // }

        if (issueCount !== 0) return

        try {
            setBackdropOpen(true)

            if (fileUpload) {
                const uploadError = await uploadDocument(contextData, documentInput.id, fileUpload)
                if (uploadError) {
                    setPageError(uploadError.message)
                    return
                }
            }
            const documentInputUpdate = documentInput.expires ? documentInput : { ...documentInput, expires: null }
            const [operation, operationName] = isNew
                ? [createDocument, 'createDocument']
                : [updateDocument, 'updateDocument']
            const updatedDocument = await callApi<Document>(contextData.user, operationName, {
                query: operation,
                variables: { item: documentInputUpdate },
            })

            if (updatedDocument.Error) {
                setPageError(updatedDocument.Error.message)
                return
            }
            const document = updatedDocument.Result

            // if (isNew) {
            //     contextData.setDocuments([...contextData.documents, document]);
            // } else {
            //     const except = contextData.documents.filter(
            //         (r) => r.id !== document.id
            //     );
            //     contextData.setDocuments([...except, document]);
            // }

            props.callback(isNew ? EditResult.Added : EditResult.Updated, document)
        } finally {
            setBackdropOpen(false)
        }
    }

    // { /* border: '1px dashed grey',  */ }
    return (
        <Box sx={{ minWidth: '400px', maxWidth: '400px' }}>
            <PageHeader title={isNew ? 'Add Document' : 'Update Document'} />
            <hr color="error" />
            <PageInfo color="error" message={pageError} />

            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {hasFile === false && fileUpload === undefined && <FormLabel>Attach Document</FormLabel>}
                    {documentInput.originalFileName && (hasFile || fileUpload !== undefined) && (
                        <FormLabel sx={{ ml: 1 }}>
                            {fileUpload === undefined
                                ? `${documentInput.originalFileName} is attached`
                                : `${documentInput.originalFileName} will be uploaded`}
                        </FormLabel>
                    )}
                </Box>
                <Box
                    component="span"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                        mt: 1,
                        width: 1,
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <DragAndDrop handleDrop={handleDrop} replace={hasFile} multiple={false} />
                </Box>
                <FormHelperText error={errorData.originalFileName ? true : false}>
                    {errorData.originalFileName}
                </FormHelperText>
            </Box>

            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                <FormLabel>Document Name</FormLabel>
                <TextField
                    autoFocus
                    name="documentName"
                    size="small"
                    value={documentInput.documentName}
                    onChange={documentNameChanged}
                    //inputProps={{ style :{ padding: "0px 0px" } }}
                />
                <FormHelperText error={errorData.documentName ? true : false}>{errorData.documentName}</FormHelperText>
            </Box>

            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        //border: "solid red 1px",
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <FormLabel>Document Type</FormLabel>
                    {documentTypeInfo && (
                        <Box
                            sx={{
                                m: 0,
                                display: 'flex',
                            }}
                        >
                            <InfoIcon
                                aria-describedby={popoverAnchor ? documentInput.id : undefined}
                                onClick={(e) => setPopoverAnchor(e.currentTarget)}
                                sx={{
                                    fontSize: 20,
                                    ml: 1,
                                }}
                            />
                            <Popover
                                id={popoverAnchor ? documentInput.id : undefined}
                                open={Boolean(popoverAnchor)}
                                anchorEl={popoverAnchor}
                                onClose={(e) => setPopoverAnchor(null)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                <Box component="div" data-color-mode="light">
                                    <MDEditor.Markdown
                                        //style={{background: "#FFFFFF", color: "#494949", borderRadius: "10", padding: "5px"}}
                                        style={{ padding: '5px' }}
                                        source={documentTypeInfo}
                                    />
                                </Box>
                            </Popover>
                        </Box>
                    )}
                </Box>
                <Select
                    name="documentTypeId"
                    size="small"
                    value={documentInput.documentTypeId}
                    onChange={documentTypeChanged}
                    // inputProps={{ readOnly: !isNew }}
                    // className={!isNew ? "Mui-disabled" : undefined}
                >
                    {documentTypesSorted.map((documentType) => (
                        <MenuItem
                            value={documentType.id}
                            key={documentType.id}
                            //onClick={(e) => {})}
                        >
                            {formatPathName(documentType.pathName)}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={errorData.documentTypeId ? true : false}>
                    {errorData.documentTypeId}
                </FormHelperText>
            </Box>

            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <FormLabel>Document Expiry</FormLabel>
                    <Checkbox
                        size="small"
                        checked={expires}
                        onChange={expiryChanged}
                        sx={{ height: '3px', ml: 0, mt: '3px' }}
                    />
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={auLocale}>
                    <DatePicker
                        value={documentInput.expires}
                        onChange={(date) => utils.tryParseDateAndSetISOValue(date, expiresChanged)}
                        readOnly={!expires}
                        //inputProps={{ readOnly: !isNew }}
                        className={!expires ? 'Mui-disabled' : undefined}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                //variant="standard"
                                disabled={!expires}
                                className={!expires ? 'Mui-disabled' : undefined}
                                sx={{ width: '100%' }}
                                // readOnly
                                // sx={{ display: `${expires ? "inline" : "none"}` }}
                            />
                        )}
                    />
                </LocalizationProvider>
                <FormHelperText error={errorData.expires ? true : false}>{errorData.expires}</FormHelperText>
            </Box>

            <DialogActions sx={{ mt: 1 }}>
                <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
                {isDuplicate ? (
                    <Button variant="contained" color="error" onClick={handleSaveDuplicate}>
                        Save Duplicate
                    </Button>
                ) : (
                    <Button variant="contained" onClick={handleSave}>
                        Save
                    </Button>
                )}
            </DialogActions>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export function DocumentEditDialog(props: EditProps) {
    return (
        <Dialog open={props.open}>
            <DialogContent>
                <DocumentEdit {...props} />
            </DialogContent>
        </Dialog>
    )
}
