import { API } from 'aws-amplify'
import { ContextDataInterface, UserWrapper } from './userContext'
import { ErrorSessionLapsed, getErrorMessage, getErrorMessageFromApiResult, isUnauthorisedError } from './errorUtils'
import { getUserById, getDocumentTypes, getDocumentsForUser } from '../graphql/queries'
import { User, Document, DocumentType, Application } from '../API'
import { GraphQLOptions, GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql'

export const getApplicationTemplates = /* GraphQL */ `
    query GetApplicationTemplates {
        getApplicationTemplates {
            id
            name
            userId
            updated
            applicationSections {
                id
                applicationId
                name
                applicationDocuments {
                    id
                    applicationSectionId
                    documentTypeId
                }
            }
        }
    }
`

export const getApplicationsForUser = /* GraphQL */ `
    query GetApplicationsForUser($pk: ID!) {
        getApplicationsForUser(pk: $pk) {
            id
            templateId
            templateVersion
            name
            userId
            updated
            applicationSections {
                id
                applicationId
                name
                created
                updated
                applicationDocuments {
                    id
                    applicationSectionId
                    documentTypeId
                    documentId
                    created
                    updated
                }
            }
        }
    }
`

export type ApiResult<TType> = {
    Result: TType | undefined
    Error: Error | undefined
}

async function doCallApi<TType>(
    user: UserWrapper,
    queryName: string,
    query: GraphQLOptions,
    retryOnUnauthorised: boolean
): Promise<ApiResult<TType>> {
    try {
        const okToProceed = await user.checkSessionActive()
        if (!okToProceed) {
            user.signOut()
            return {
                Result: undefined,
                Error: ErrorSessionLapsed,
            }
        }
        const queryWithPermissions = {
            ...query,
            authMode: GRAPHQL_AUTH_MODE.OPENID_CONNECT,
            authToken: user.idToken.__raw,
        }
        const response = (await API.graphql(queryWithPermissions)) as GraphQLResult<any>
        if (response.data) {
            console.log('API called successfully. Returned data: %o', response.data)
            const typedResult = response.data[queryName] as TType // TODO REVISIT
            if (typedResult) return { Result: typedResult, Error: undefined }
            else
                return {
                    Result: undefined,
                    Error: new Error('The api result was not of the expected type'),
                }
        }
        console.log('Error calling API. Returned data: %o', response.errors)
        const errorMessage = getErrorMessageFromApiResult(response)
        return { Result: undefined, Error: new Error(errorMessage) }
    } catch (error) {
        const errorMessage = getErrorMessage('calling the api', error)
        if (isUnauthorisedError(errorMessage)) {
            if (retryOnUnauthorised) {
                const renewed = await user.renewSession()
                if (renewed) return doCallApi(user, queryName, query, false)
            }
            user.signOut()
        }
        console.log(errorMessage)
        return { Result: undefined, Error: new Error(errorMessage) }
    }
}

export async function callApi<TType>(
    user: UserWrapper,
    queryName: string,
    query: GraphQLOptions
): Promise<ApiResult<TType>> {
    return doCallApi(user, queryName, query, true)
}

export async function retrieveLedgeUser(user: UserWrapper, userId: string): Promise<ApiResult<User>> {
    return callApi<User>(user, 'getUserById', {
        query: getUserById,
        variables: { pk: userId },
    })
}

export async function retrieveDocuments(user: UserWrapper, userId: string): Promise<ApiResult<Array<Document>>> {
    return callApi<Array<Document>>(user, 'getDocumentsForUser', {
        query: getDocumentsForUser,
        variables: { pk: userId },
    })
}

export async function retrieveApplications(user: UserWrapper, userId: string): Promise<ApiResult<Array<Application>>> {
    return callApi<Array<Application>>(user, 'getApplicationsForUser', {
        query: getApplicationsForUser,
        variables: { pk: userId },
    })
}

export async function retrieveDocumentTypes(user: UserWrapper): Promise<ApiResult<Array<DocumentType>>> {
    return callApi<Array<DocumentType>>(user, 'getDocumentTypes', {
        query: getDocumentTypes,
    })
}

export async function retrieveTemplates(user: UserWrapper): Promise<ApiResult<Array<Application>>> {
    return callApi<Array<Application>>(user, 'getApplicationTemplates', {
        query: getApplicationTemplates,
    })
}

export async function updateContextForUser(
    contextData: ContextDataInterface,
    userId: string
): Promise<ApiResult<[User, Document[]]>> {
    const ledgeUser = contextData.ledgeUser

    if (!ledgeUser) return { Result: undefined, Error: new Error('The ledge user has not been set!') }

    if (ledgeUser.id === userId) return { Result: [contextData.ledgeUser!, contextData.documents], Error: undefined }

    if (ledgeUser.id != contextData.user.sub && contextData.user.isAdminOrRecruiter() === false)
        return { Result: undefined, Error: new Error('Invalid permissions!') }

    const [userResult, documentsResult] = await Promise.all([
        retrieveLedgeUser(contextData.user, userId),
        retrieveDocuments(contextData.user, userId),
    ])

    const error = userResult.Error ?? documentsResult.Error

    if (error) return { Result: undefined, Error: error }

    contextData.setLedgeUser(userResult.Result)
    contextData.setDocuments(documentsResult.Result ?? [])

    return { Result: [userResult.Result!, documentsResult.Result!], Error: undefined }
}

export async function clearCandidateContext(contextData: ContextDataInterface) {
    return await updateContextForUser(contextData, contextData.user.sub)
}
