import { UserWrapper } from '../common/userContext'
import { callApi } from '../common/apiUtils'
import { Application, ApplicationInput, ApplicationSectionInput, ApplicationDocumentInput } from '../API'

const { v4: uuid } = require('uuid')

export const templateUserId = '00000000-0000-0000-0000-000000000000'

export type ApplicationLookupResult = {
    applicationInput: ApplicationInput | undefined
    error: Error | undefined
    isTemplate: boolean
    basedOn: string
}

export const getApplicationById = /* GraphQL */ `
    query GetApplicationById($pk: ID!) {
        getApplicationById(pk: $pk) {
            id
            name
            templateId
            templateVersion
            templateApplied
            userId
            created
            updated
            applicationSections {
                id
                applicationId
                name
                created
                updated
                applicationDocuments {
                    id
                    applicationSectionId
                    documentTypeId
                    documentId
                    created
                    updated
                }
            }
        }
    }
`

export function getNewApplication(userId: string): ApplicationLookupResult {
    const applicationInput: ApplicationInput = {
        id: uuid(),
        userId: userId,
        name: '',
        applicationSections: [],
    }
    return {
        applicationInput: applicationInput,
        error: undefined,
        isTemplate: userId === templateUserId,
        basedOn: '',
    } as ApplicationLookupResult
}

export async function getExistingApplication(
    user: UserWrapper,
    id: string,
    copyAsNew: boolean,
    copyAsUserId: string
): Promise<ApplicationLookupResult> {
    const application = await callApi<Application>(user, 'getApplicationById', {
        query: getApplicationById,
        variables: { pk: id },
    })
    const applicationInput = application.Result
        ? convertToApplicationInput(application.Result, copyAsNew, copyAsUserId)
        : undefined

    const result: ApplicationLookupResult = {
        applicationInput: applicationInput,
        error: application.Error,
        isTemplate: application.Result?.userId === templateUserId ?? true,
        basedOn: copyAsNew ? application.Result?.name ?? '' : '',
    }
    return result
}

export function convertToApplicationInput(application: Application, copyAsNew: boolean, copyAsuserId: string) {
    const sections = application.applicationSections.map((s) => {
        const documents = s.applicationDocuments.map((d) => {
            const documentInput: ApplicationDocumentInput = {
                isNew: copyAsNew,
                id: copyAsNew ? uuid() : d.id,
                applicationSectionId: d.applicationSectionId,
                documentTypeId: d.documentTypeId,
                documentId: d.documentId,
            }
            return documentInput
        })

        const sectionInput: ApplicationSectionInput = {
            isNew: copyAsNew,
            id: copyAsNew ? uuid() : s.id,
            applicationId: s.applicationId,
            name: s.name,
            applicationDocuments: documents.sort((a, b) => a.id.localeCompare(b.id)),
        }
        return sectionInput
    })

    const sectionsSorted = sections.sort((a, b) => a.name.localeCompare(b.name))

    return {
        id: copyAsNew ? uuid() : application.id,
        userId: copyAsNew ? copyAsuserId : application.userId,
        templateId: copyAsNew ? application.id : application.templateId ?? templateUserId,
        templateVersion: copyAsNew ? application.updated : application.templateVersion ?? 0,
        name: application.name,
        applicationSections: sectionsSorted,
    }
}
