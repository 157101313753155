import { useContext } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Link, Breadcrumbs } from '@mui/material'
import { UserContext } from '../../common/userContext'

export default function CandidateBreadcrumbs() {
    const contextData = useContext(UserContext)
    const location = useLocation()
    const pathname = location.pathname

    if (!contextData.user.isAdminOrRecruiter()) return <></>

    if (!pathname.includes('ocuments') && !pathname.includes('pplication') && !pathname.includes('andidate'))
        return (
            <></>
        )

    const candidateSet = pathname !== '/candidates' 
    var locationCrumb = ''
    if (pathname.includes('ocuments')) locationCrumb = 'documents'
    if (pathname.includes('pplication')) locationCrumb = 'checklist'

    return (
        <Breadcrumbs aria-label="breadcrumb" color="white" sx={{ ml: 0 }}>
            <Link component={RouterLink} to={`/candidates`} sx={{ textDecoration: 'none', color: '#FFFFFF' }}>
                candidates
            </Link>
            {candidateSet && (
                <Link
                    component={RouterLink}
                    to={`/candidate/${contextData.ledgeUser?.id}`}
                    sx={{ textDecoration: 'none', color: '#FFFFFF' }}
                >{`${contextData.ledgeUser?.firstName} ${contextData.ledgeUser?.lastName}`}</Link>
            )}
            {locationCrumb && (
                <Link
                    component={RouterLink}
                    to={pathname}
                    sx={{ textDecoration: 'none', color: '#FFFFFF' }}
                >{`${locationCrumb}`}</Link>
            )}
        </Breadcrumbs>
    )
}
