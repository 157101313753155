import { Box, FormControl, InputLabel, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import { QualificationLevel, PositionType, UserInput } from '../../API'
import * as common from './profileCommon'

export type ApplicationSectionProps = {
    userInput: UserInput
}

export function ProfileEmploymentDetails({ userInput, setValue, gridItemWidth }: common.ProfileSectionProps) {
    return (
        <Box
            sx={{
                boxSizing: 'border-box',
                p: 1,
                borderRadius: '5px',
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Employment Details</Typography>
                    <p>
                        Tell us about your current and past employment. This will help us to match you with jobs looking
                        for your experience
                    </p>
                </Grid>

                <Grid item xs={gridItemWidth}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        type="number"
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                        }}
                        name="yearsOfExperience"
                        label="Years Experience"
                        value={userInput.yearsOfExperience ?? 0}
                        onChange={(e) => setValue(e.target.name, Number.parseInt(e.target.value))}
                        sx={{ backgroundColor: 'white' }}
                    />
                </Grid>

                <Grid item xs={gridItemWidth}>
                    <FormControl fullWidth variant="outlined" size="small" margin="dense">
                        <InputLabel id="levelOfTrainingLabel">Level of Training</InputLabel>
                        <Select
                            labelId="levelOfTrainingLabel"
                            label="Level of Training"
                            name="level"
                            value={userInput.level ?? ''}
                            onChange={(e) =>
                                setValue(e.target.name, e.target.value ? QualificationLevel[e.target.value] : undefined)
                            }
                            MenuProps={{
                                PaperProps: { sx: { maxHeight: 200 } },
                            }}
                            sx={{ backgroundColor: 'white' }}
                        >
                            {Object.values(QualificationLevel).map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={gridItemWidth}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        inputProps={{ maxLength: 256 }}
                        name="currentJobTitle"
                        label="Current Job Title"
                        value={userInput.currentJobTitle ?? ''}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                        sx={{ backgroundColor: 'white' }}
                    />
                </Grid>

                <Grid item xs={gridItemWidth}>
                    <FormControl fullWidth variant="outlined" size="small" margin="dense">
                        <InputLabel id="currentJobTypeLabel">Current Job Type</InputLabel>
                        <Select
                            labelId="currentJobTypeLabel"
                            label="Current Job Type"
                            name="currentJobType"
                            value={userInput.currentJobType ?? ''}
                            onChange={(e) =>
                                setValue(e.target.name, e.target.value ? PositionType[e.target.value] : undefined)
                            }
                            MenuProps={{
                                PaperProps: { sx: { maxHeight: 200 } },
                            }}
                            sx={{ backgroundColor: 'white' }}
                        >
                            {Object.values(PositionType).map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}
