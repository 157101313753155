import { ContextDataInterface } from '../../common/userContext'
import { DocumentInput, DocumentType } from '../../API'

export function hasExisting(
    isNew: boolean,
    document: DocumentInput,
    contextData: ContextDataInterface
): [boolean, boolean, DocumentType | undefined] {
    const documentType = contextData.documentTypes.find((d) => d.id === document.documentTypeId)
    if (!documentType) return [false, false, undefined] // Logic error here revisit
    const existingOthers = contextData.documents.filter(
        (d) => d.documentTypeId === documentType.id && d.id !== document.id
    )
    const duplicateName = existingOthers.find((d) => d.documentName === document.documentName) !== undefined
    const duplicateType =
        isNew && !documentType.hasMany && !documentType.applicationSpecific && existingOthers.length > 0
    return [duplicateName, duplicateType, documentType]
}
