import { useState, useContext } from 'react'
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogActions,
    FormHelperText,
    FormLabel,
    List,
    ListItemButton,
    ListItemText,
    MenuItem,
    Paper,
    Popover,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import MDEditor from '@uiw/react-md-editor'
import auLocale from 'date-fns/locale/en-AU'
import { callApi } from '../../common/apiUtils'
import { UserContext, getDocumentTypeInfoFromContext, ContextDataInterface } from '../../common/userContext'
import { uploadDocument, removeExtension, userDocumentExists } from '../../common/documentUtils'
import { hasExisting } from '../documents/documentCommon'
import { DragAndDrop } from '../common/dragAndDrop'
import { PageHeader } from '../common/pageHeader'
import { PageInfo } from '../common/pageInfo'
import { getDocumentTypeName } from './applicationCommon'
import { getErrorMessage } from '../../common/errorUtils'
import { Document, DocumentInput, DocumentType } from '../../API'
import { createDocument } from '../../graphql/mutations'
import * as utils from '../../common/typeUtils'

const { v4: uuid } = require('uuid')

export type ApplicationSectionDocumentEditNewProps = {
    open: boolean
    applicationDocumentId: string
    documentType: DocumentType
    selected: Document | undefined
    available: Document[]
    callback: (applicationDocumentId: string, document: Document | undefined) => void
    handleClose: (event: any) => void
}

function ApplicationSectionDocumentEditNew({
    applicationDocumentId,
    documentType,
    selected,
    available,
    callback,
    handleClose,
}: ApplicationSectionDocumentEditNewProps) {
    const contextData = useContext(UserContext)

    const existingCount = contextData.documents.filter((d) => d.documentTypeId === documentType.id)
    const typeName =  utils.tryParsePathName(documentType.pathName) ?? ''
    const defaultName = existingCount.length > 0 ? `${typeName} ${existingCount.length + 1}` : typeName

    const initialDocument: DocumentInput = {
        id: uuid(),
        userId: contextData.ledgeUser?.id ?? '', // Use ledgeUser for id to support recruiter mode
        documentName: defaultName,
        documentTypeId: documentType.id,
        uploaded: false,
        originalFileName: '',
        contentType: '',
        expires: undefined,
        details: undefined,
    }

    const documentTypeName = getDocumentTypeName(documentType)
    const [documentInput, setDocumentInput] = useState<DocumentInput>(initialDocument)
    const [fileUpload, setFileUpload] = useState<File | undefined>(undefined)
    const [expires, setExpires] = useState(false)
    const [expiresError, setExpiresError] = useState('')
    const [nameError, setNameError] = useState('')
    const [replacement, setReplacement] = useState<Document | undefined>()
    const [dropMessage, setDropMessage] = useState('')
    const [uploadingDocument, setUploadingDocument] = useState(false)
    const [pageError, setPageError] = useState('')

    const setDocumentChanged = (selectedFile: File) => {
        const fileName = selectedFile.name
        const contentType = selectedFile.type
        setFileUpload(selectedFile)
        setDropMessage(selectedFile.name)
        setReplacement(undefined)
        setDocumentInput({
            ...documentInput,
            documentName: documentInput.documentName ? documentInput.documentName : removeExtension(fileName),
            uploaded: true,
            originalFileName: fileName,
            contentType: contentType,
        })
    }

    const setDocumentInputValue = (name: string, value: string | undefined | null) => {
        setDocumentInput({
            ...documentInput,
            [name]: value,
        })
    }

    const documentNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDocumentInput({
            ...documentInput,
            documentName: event.target.value,
        })
        setNameError(event.target.value ? '' : 'required field')
    }

    const checkExpiryChanged = (dateString: string | null | undefined) => {
        if (!utils.hasExpired(dateString)) setExpiresError('')
        else setExpiresError('the date specified is in the past')
    }

    const expiryChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExpires(event.target.checked)
        if (!event.target.checked) setDocumentInputValue('expires', undefined)
        else setDocumentInputValue('expires', utils.getNextYearISODate())
        setExpiresError('expires')
    }

    const expiresChanged = (date: string | null) => {
        setDocumentInputValue('expires', date)
        checkExpiryChanged(date)
    }

    const handleCancel = (event) => {
        handleClose(selected)
    }

    const handleSaveNewDocument = async () => {
        if (!documentInput.userId) {
            setPageError('there is no active user')
            return undefined
        } 

        let issueCount = 0

        setPageError('')

        const [duplicateName, duplicateType, documentType] = hasExisting(true, documentInput, contextData)

        if (duplicateName) {
            setNameError(`there is already a document named ${documentInput.documentName}!`)
            issueCount = issueCount + 1
        } else if (utils.isValidName(documentInput.documentName)) {
            setNameError('')
        } else {
            setNameError('required field')
            issueCount = issueCount + 1
        }

        if (!utils.hasExpired(documentInput.expires)) {
            setExpiresError('')
        } else {
            setExpiresError('the date specified is in the past')
            issueCount = issueCount + 1
        }

        if (!documentInput.userId) {
            setPageError('there is no active user')
            return undefined
        } 

        if (issueCount !== 0) return

        try {
            setUploadingDocument(true)

            if (fileUpload) {
                const uploadError = await uploadDocument(contextData, documentInput.id, fileUpload)
                if (uploadError) {
                    setPageError(uploadError.message)
                    return
                }
            }
            const documentInputUpdate = documentInput.expires ? documentInput : { ...documentInput, expires: null }
            const uploadedDocument = await callApi<Document>(contextData.user, 'createDocument', {
                query: createDocument,
                variables: { item: documentInputUpdate },
            })

            if (!uploadedDocument.Result) {
                setPageError(uploadedDocument.Error?.message ?? 'there was an error uploading the document')
                return
            }

            contextData.setDocuments([...contextData.documents, uploadedDocument.Result])

            return uploadedDocument.Result
        } finally {
            setUploadingDocument(false)
        }
    }

    const handlUpdate = async () => {
        if (replacement) {
            callback(applicationDocumentId, replacement)
            return
        }

        if (fileUpload) {
            const uploadedDocument = await handleSaveNewDocument()
            if (uploadedDocument) callback(applicationDocumentId, uploadedDocument)
            return
        }

        setPageError(
            getErrorMessage('selecting a document', 'Please select a document or upload a new document first!')
        )
    }

    const handleDrop = (files: File[]) => {
        console.log(files)
        if (files.length === 0) return
        setDocumentChanged(files[0])
    }

    return (
        <Box sx={{ minWidth: '400px', maxWidth: '400px' }}>
            <PageHeader title={'Edit'} />
            <hr color="error" />
            <PageInfo message={pageError} color="error" />

            {available.length > 0 && (
                <>
                    <Typography sx={{ mt: 1 }}>Swap Document</Typography>
                    <Paper sx={{ maxHeight: 150, overflow: 'auto', mt: 1 }}>
                        <List dense>
                            {available.map((row, index) => (
                                <ListItemButton
                                    key={index}
                                    onClick={() => {
                                        setPageError('')
                                        setReplacement(row)
                                        setFileUpload(undefined)
                                        setDropMessage('')
                                    }}
                                    selected={replacement?.id === row.id}
                                >
                                    <ListItemText
                                        primary={row.documentName}
                                        secondary={documentTypeName}
                                        primaryTypographyProps={{
                                            display: 'inline',
                                            ml: 1,
                                            fontWeight: replacement?.id === row.id ? 'bold' : undefined,
                                        }}
                                        secondaryTypographyProps={{
                                            display: 'inline',
                                            //fontStyle: "italic",
                                            ml: 1,
                                        }}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    </Paper>
                </>
            )}

            <Typography sx={{ mt: 2, mb: 0 }}>
                {available.length > 0 ? 'Or Upload New Document' : 'Upload Document'}
            </Typography>

            <Box sx={{ mt: 0, display: 'flex', flexDirection: 'column' }}>
                <Box
                    component="span"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                        mt: 1,
                        width: 1,
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <DragAndDrop handleDrop={handleDrop} replace={false} multiple={false} message={dropMessage} />
                </Box>
                {/* <FormHelperText error={errorData.originalFileName ? true : false}>
                    {errorData.originalFileName}
                </FormHelperText> */}
            </Box>

            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                <FormLabel>Document Name</FormLabel>
                <TextField
                    autoFocus
                    name="documentName"
                    size="small"
                    value={documentInput.documentName}
                    onChange={documentNameChanged}
                    //inputProps={{ style :{ padding: "0px 0px" } }}
                />
                <FormHelperText error={nameError ? true : false}>{nameError}</FormHelperText>
            </Box>

            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <FormLabel>Document Expiry</FormLabel>
                    <Checkbox
                        size="small"
                        checked={expires}
                        onChange={expiryChanged}
                        sx={{ height: '3px', ml: 0, mt: '3px' }}
                    />
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={auLocale}>
                    <DatePicker
                        value={documentInput.expires}
                        onChange={(date) => utils.tryParseDateAndSetISOValue(date, expiresChanged)}
                        readOnly={!expires}
                        //inputProps={{ readOnly: !isNew }}
                        className={!expires ? 'Mui-disabled' : undefined}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                //variant="standard"
                                disabled={!expires}
                                className={!expires ? 'Mui-disabled' : undefined}
                                sx={{ width: '100%' }}
                                // readOnly
                                // sx={{ display: `${expires ? "inline" : "none"}` }}
                            />
                        )}
                    />
                </LocalizationProvider>
                <FormHelperText error={expiresError ? true : false}>{expiresError}</FormHelperText>
            </Box>

            <DialogActions sx={{ mt: 1 }}>
                <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    onClick={handlUpdate}
                    disabled={replacement === undefined && fileUpload === undefined}
                    loading={uploadingDocument}
                >
                    Update
                </LoadingButton>
            </DialogActions>
        </Box>
    )
}

export function ApplicationSectionDocumentEditNewDialog(props: ApplicationSectionDocumentEditNewProps) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogContent>
                <ApplicationSectionDocumentEditNew {...props} />
            </DialogContent>
        </Dialog>
    )
}
