import { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    FormLabel,
    DialogActions,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material'
import { PageHeader } from '../common/pageHeader'
import { PageInfo } from '../common/pageInfo'
import { ApplicationEditMode } from './applicationCommon'
import { UserContext } from '../../common/userContext'
import { Application } from '../../API'

function sortTemplates(templates: Application[]): Application[] {
    const sorted = [...templates].sort((a, b) => {
        return a.name.localeCompare(b.name)
    })
    return sorted
}

export type ApplicationAddProps = {
    open: boolean
    existing: Application[]
    handleClose: (event: any) => void
}

export function ApplicationAdd(props: ApplicationAddProps) {
    const navigate = useNavigate()
    const contextData = useContext(UserContext)
    const availableTemplates = sortTemplates(contextData.templates.filter((t) => props.existing.find((e) => e.templateId === t.id) === undefined))
    const [pageError, setPageError] = useState<string>(availableTemplates.length === 0 ? 'You are already using all of the available templates' : '')
    const [template, setTemplate] = useState<Application | undefined>(availableTemplates.length > 0 ? availableTemplates[0] : undefined)

    const isDuplicate = (selectedTemplate: Application | undefined) => {
        const duplicate = props.existing.find((e) => e.templateId === selectedTemplate?.id)
        return duplicate !== undefined
    }

    const templateChanged = (event: SelectChangeEvent<any>) => {
        const template = availableTemplates.find((t) => t.id === event.target.value)
        setTemplate(template)
        if (isDuplicate(template)) {
            setPageError('You already have a checklist based on this template!')
        } else {
            setPageError('')
        }
    }

    const handleCancel = (e) => {
        props.handleClose(e)
    }

    const handleSubmit = () => {
        if (!template || isDuplicate(template)) return
        navigate(`/applicationEdit/${template.id}/${ApplicationEditMode[ApplicationEditMode.AddFromTemplate]}`)
    }

    return (
        <Box sx={{ minWidth: '400px', maxWidth: '400px' }}>
            <PageHeader title="New Credentials Checklist" />
            <hr color="error" />
            <PageInfo message={pageError} color="error" />
            <Box>
                <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                    <FormLabel sx={{ mt: 2 }}>Checklist Template</FormLabel>
                    <Select name="templateId" size="small" disabled={availableTemplates.length === 0} value={template?.id} onChange={templateChanged}>
                        {availableTemplates.map((next) => (
                            <MenuItem value={next.id} key={next.id}>
                                {next.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <DialogActions sx={{ mt: 1 }}>
                    <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSubmit}>
                        Continue
                    </Button>
                </DialogActions>
            </Box>
        </Box>
    )
}

export function ApplicationAddDialog(props: ApplicationAddProps) {
    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogContent>
                <ApplicationAdd {...props} />
            </DialogContent>
        </Dialog>
    )
}
