import { useState, useContext, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Backdrop, Box, Button, Tooltip, Grid, CircularProgress, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import { callApi } from '../../common/apiUtils'
import { ConfirmDialogUnsavedChanges } from '../common/confirmDialogUnsavedChanges'
import { UserContext, UserWrapper } from '../../common/userContext'
import { useCallbackPrompt } from '../../common/useCallbackPrompt'
import { getErrorMessage } from '../../common/errorUtils'
import { PageHeader } from '../common/pageHeader'
import { PageInfo } from '../common/pageInfo'
import { ConfirmDialog, ConfirmDialogProps } from '../common/confirmDialog'
import { TemplateSectionEdit, TemplateDocumentData, TemplateSectionData } from './templateSection'
import { TemplateSectionDialog, TemplateSectionProps } from './templateSectionDialog'
import {
    Application,
    ApplicationInput,
    ApplicationSectionInput,
    ApplicationDocumentInput,
    DocumentType,
} from '../../API'
import {
    templateUserId,
    getNewApplication,
    getExistingApplication,
    ApplicationLookupResult,
} from '../../common/applicationUtils'
import { ContextDataInterface } from '../../common/userContext'
import { groupBy, filterDefined, toMap } from '../../common/typeUtils'
import { applyTemplateToApplicationChecklists } from '../../common/applicationSync'
import { isEqual, omit, cloneDeep } from 'lodash'

const { v4: uuid } = require('uuid')

export const createApplicationTemplate = /* GraphQL */ `
    mutation CreateApplicationTemplate($item: ApplicationInput!) {
        createApplicationTemplate(item: $item) {
            id
            name
            userId
            templateApplied
            updated
            applicationSections {
                id
                applicationId
                name
                applicationDocuments {
                    id
                    applicationSectionId
                    documentTypeId
                    documentId
                }
            }
        }
    }
`
export const updateApplicationTemplate = /* GraphQL */ `
    mutation UpdateApplicationTemplate($item: ApplicationInput!) {
        updateApplicationTemplate(item: $item) {
            id
            name
            userId
            templateApplied
            updated
            applicationSections {
                id
                applicationId
                name
                applicationDocuments {
                    id
                    applicationSectionId
                    documentTypeId
                    documentId
                }
            }
        }
    }
`

export const updateApplicationTemplateApplied = /* GraphQL */ `
    mutation UpdateApplicationTemplateApplied($pk: ID!) {
        updateApplicationTemplateApplied(pk: $pk) {
            id
            templateApplied
        }
    }
`

async function getApplication(user: UserWrapper, id: string | undefined): Promise<ApplicationLookupResult> {
    if (!id) return getNewApplication(templateUserId)
    return getExistingApplication(user, id, false, templateUserId)
}

function getSectionData(applicationSection: ApplicationSectionInput, contextData: ContextDataInterface) {
    let documentDatas: TemplateDocumentData[] = []
    for (let d of applicationSection.applicationDocuments) {
        const documentType = contextData.documentTypes.find((u) => u.id === d.documentTypeId)
        if (!documentType)
            // Was the document type deleted by admins?
            continue
        const documentData: TemplateDocumentData = {
            applicationDocument: {
                isNew: false,
                applicationSectionId: applicationSection.id,
                id: d.id,
                documentTypeId: d.documentTypeId,
                documentId: d.documentId,
            },
            documentType: documentType,
        }
        documentDatas = [...documentDatas, documentData]
    }
    const sectionData: TemplateSectionData = {
        applicationSection: {
            id: applicationSection.id,
            applicationId: applicationSection.applicationId,
            isNew: false,
            name: applicationSection.name,
            applicationDocuments: [], // will be filled in later for any updates
        },
        documents: documentDatas,
    }
    return getUpdatedSection(
        sectionData.applicationSection.applicationId,
        sectionData.applicationSection.name,
        filterDefined(sectionData.documents.map((d) => d.documentType)),
        sectionData
    )
}

export function getNewTemplateDocument(applicationSectionId: string, documentType: DocumentType) {
    const updated: ApplicationDocumentInput = {
        isNew: true,
        id: uuid(),
        applicationSectionId: applicationSectionId,
        documentTypeId: documentType.id,
        documentId: undefined,
    }
    const updatedData: TemplateDocumentData = {
        applicationDocument: updated,
        documentType: documentType,
    }
    return updatedData
}

function getUpdatedSection(
    applicationId: string,
    sectionName: string,
    documentTypes: DocumentType[],
    existingData: TemplateSectionData | undefined
) {
    const existing = existingData?.applicationSection
    const existingDocuments = existingData?.documents ?? []
    const updated: ApplicationSectionInput = {
        isNew: existing?.isNew ?? true,
        id: existing?.id ?? uuid(),
        applicationId: applicationId,
        name: sectionName,
        applicationDocuments: [],
    }
    const documentTypesMap = groupBy(documentTypes, (d) => d.id)

    const documentsMap = groupBy(existingDocuments, (d) => d.applicationDocument.documentTypeId)

    let updatedDocuments: TemplateDocumentData[] = []
    for (let [key, values] of documentTypesMap) {
        let unmatchedDocumentTypes: DocumentType[] = values
        if (documentsMap.has(key)) {
            const existing = documentsMap.get(key)!
            // Only take up to the required number of values, throw the rest away
            existing.splice(values.length)
            updatedDocuments = [...updatedDocuments, ...existing]
            unmatchedDocumentTypes = values.slice(existing.length, undefined)
        }
        for (let unmatched of unmatchedDocumentTypes) {
            updatedDocuments = [...updatedDocuments, getNewTemplateDocument(updated.id, unmatched)]
        }
    }

    if (updatedDocuments.length !== documentTypes.length) console.log('Logic error matching section documents')

    const updatedProps: TemplateSectionData = {
        applicationSection: updated,
        documents: updatedDocuments,
    }

    return updatedProps
}

function getSectionInputs(applicationId: string, sectionDatas: TemplateSectionData[]) {
    // Sorted for comparison
    return sectionDatas
        .sort((a, b) => a.applicationSection.name.localeCompare(b.applicationSection.name))
        .map((s) => {
            const documentInputs = s.documents
                .map((d) => d.applicationDocument)
                .sort((a, b) => a.id.localeCompare(b.id)) // getDocumentInput(s.applicationSection.id, d));
            return {
                ...s.applicationSection,
                applicationId: applicationId,
                applicationDocuments: documentInputs,
            } as ApplicationSectionInput
        })
}

function hasChanges(orig: ApplicationInput, updatedDetails: ApplicationInput, updatedSections: TemplateSectionData[]) {
    const updatedSectionInputs = getSectionInputs(updatedDetails.id, updatedSections)
    const updated = {
        ...updatedDetails,
        applicationSections: updatedSectionInputs.sort((a, b) => a.name.localeCompare(b.name)),
    }
    return isEqual(orig, updated) === false
}

function getUpdatedInput(input: ApplicationInput, name: string, value: any) {
    const updateValue = value ?? ''
    const updated: ApplicationInput = {
        ...input,
        [name]: updateValue,
    }
    return updated
}

export default function EditTemplate() {
    const { id } = useParams()
    const isNew = id === 'add'
    const navigate = useNavigate()
    const navigateAway = useRef(false)
    const contextData = useContext(UserContext)
    const [orig, setOrig] = useState<ApplicationInput>({} as ApplicationInput)
    const [details, setDetails] = useState<ApplicationInput>()
    const [pageError, setPageError] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)
    const [backdropOpen, setBackdropOpen] = useState<boolean>(true)
    const [sections, setSections] = useState<TemplateSectionData[]>([])
    const [navigateRequest, setNavigateRequest] = useState(new Date())
    const [unsavedChanges, setUnsavedChanges] = useState(isNew)
    const [templateApplied, setTemplateApplied] = useState(true)
    const [templateSectionProps, setTemplateSectionProps] = useState<TemplateSectionProps>({
        open: false,
        sectionName: undefined,
        selected: [],
        takenSections: [],
        callback: (r, d) => {},
        handleClose: (e) => {},
    })
    const [showUnsavedChangesPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(unsavedChanges)
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps>({
        open: false,
        title: '',
        description: '',
        action: '',
        data: '',
        callback: () => {},
    })

    function getSection(name: string) {
        return sections.find((s) => s.applicationSection.name === name)
    }

    function getSectionsExcept(name: string) {
        return [...sections.filter((s) => s.applicationSection.name !== name)]
    }

    const getUpdatedApplication = (mainDetails: ApplicationInput | undefined) => {
        if (!mainDetails || !mainDetails.name || isDuplicateName(mainDetails)) return undefined
        const sectionInputs = getSectionInputs(mainDetails.id, sections)
        return {
            ...details,
            applicationSections: sectionInputs,
        } as ApplicationInput
    }

    function updateUnsavedChanges(details: ApplicationInput | undefined, sections: TemplateSectionData[]) {
        if (!details) return
        if (isNew) return
        const hasUnsavedChanges = hasChanges(orig, details, sections)
        setUnsavedChanges(hasUnsavedChanges)
    }

    const confirmNavigationWithSave = async (saveFirst: boolean) => {
        if (saveFirst) await saveChanges(false, details) // THis will navigate after the save
        confirmNavigation()
    }

    const isDuplicateName = (applicationDetails: ApplicationInput) => {
        const duplicate = contextData.templates.find(
            (t) => t.id !== applicationDetails.id && t.name === applicationDetails.name
        )
        return duplicate !== undefined
    }

    const setChecklistName = (value: string | undefined) => {
        if (!details) return
        const updated = getUpdatedInput(details, 'name', value)
        setDetails(updated)
        updateUnsavedChanges(updated, sections)
        if (isDuplicateName(updated)) {
            setPageError('There is already a template with this name!')
        } else {
            setPageError('')
        }
    }

    useEffect(() => {
        const getApplicationTemplate = async () => {
            const result = await getApplication(contextData.user, isNew ? undefined : id)
            if (!result.applicationInput) {
                setBackdropOpen(false)
                setPageError(getErrorMessage('retrieving the template', result.error))
                return
            }
            const orig = omit(result.applicationInput, ['templateApplied']) // This flag is updated separatley
            setOrig(orig)
            setDetails(orig)
            const sectionDatas = result.applicationInput.applicationSections.map((s) => getSectionData(s, contextData))
            setSections(sectionDatas)
            setTemplateApplied(result.applicationInput.templateApplied ?? true)
            setBackdropOpen(false)
        }
        if (loading) {
            setLoading(false)
            getApplicationTemplate()
        }
    }, [])

    useEffect(() => {
        if (navigateAway.current && unsavedChanges === false) {
            navigate('/templateAdmin')
        }
    }, [unsavedChanges, navigateRequest, navigate])

    const navigateToList = () => {
        // Use effect above waits for unsavedChanges to be set to turn off the confirm dialog before navigating
        navigateAway.current = true
        setUnsavedChanges(false)
        setNavigateRequest(new Date())
    }

    const saveChanges = async (
        navigateAfterSave: boolean,
        mainDetails: ApplicationInput | undefined
    ): Promise<Application | undefined> => {
        const updated = getUpdatedApplication(mainDetails)
        if (!updated) return
        setBackdropOpen(true)
        const [operation, operationName] = isNew
            ? [createApplicationTemplate, 'createApplicationTemplate']
            : [updateApplicationTemplate, 'updateApplicationTemplate']
        const updatedApplication = await callApi<Application>(contextData.user, operationName, {
            query: operation,
            variables: { item: updated },
        })
        if (updatedApplication.Error) {
            setPageError(getErrorMessage('updating the template', updatedApplication.Error))
            setBackdropOpen(false)
            return undefined
        }
        const saved = updatedApplication.Result!
        if (isNew) {
            contextData.setTemplates([...contextData.templates, saved])
        } else {
            const except = contextData.templates.filter((r) => r.id !== saved.id)
            contextData.setTemplates([...except, saved])
        }
        setUnsavedChanges(false)
        setTemplateApplied(isNew)
        setBackdropOpen(false)
        if (navigateAfterSave) navigateToList()
        return saved
    }

    const handleAddSection = () => {
        editSection(undefined)
    }

    const applyTemplate = async () => {
        try {
            if (!details || isNew) return
            const saved = await saveChanges(false, details)
            setBackdropOpen(true)
            if (!saved) return
            const errorResult = await applyTemplateToApplicationChecklists(contextData, saved)
            if (errorResult) {
                setPageError(getErrorMessage('applying the template', errorResult))
                return
            }
            const updated = getUpdatedInput(details, 'templateApplied', true)
            setDetails(updated)
            const updatedApplication = await callApi<Application>(
                contextData.user,
                'updateApplicationTemplateApplied',
                {
                    query: updateApplicationTemplateApplied,
                    variables: { pk: details.id },
                }
            )
            if (!updatedApplication.Result) {
                setPageError(getErrorMessage('updating the template', updatedApplication.Error))
                return
            }
            setTemplateApplied(true)
        } finally {
            setBackdropOpen(false)
        }
    }

    const handleEditSection = (section: TemplateSectionData) => {
        editSection(section)
    }

    const editSection = (section: TemplateSectionData | undefined) => {
        let editProps: TemplateSectionProps = {
            open: true,
            sectionName: section?.applicationSection.name,
            selected: section ? filterDefined(section.documents.map((d) => d.documentType)) : [],
            takenSections: sections.map((s) => s.applicationSection.name),
            callback: handleTemplateSectionDialogResult,
            handleClose: (e) =>
                setTemplateSectionProps({
                    ...templateSectionProps,
                    open: false,
                    sectionName: '',
                    selected: [],
                }),
        }
        setTemplateSectionProps(editProps)
    }

    const handleDeleteSection = (section: TemplateSectionData) => {
        setConfirmDialogProps({
            ...confirmDialogProps,
            open: true,
            title: 'Delete Document Group',
            action: 'Delete',
            data: section.applicationSection.name,
            description: `Are you sure that you would like to delete the document group ${section.applicationSection.name}?`,
            callback: handleDeleteSectionConfirmResult,
        })
    }

    const handleDeleteSectionConfirmResult = (action: string, data: any, result: boolean) => {
        setConfirmDialogProps({
            ...confirmDialogProps,
            open: false,
            data: '',
            description: '',
        })

        if (!result || action !== 'Delete' || !data) return

        deleteSection(data as string)
    }

    const deleteSection = (name: string) => {
        const sectionsUpdate = getSectionsExcept(name)
        setSections(sectionsUpdate)
        updateUnsavedChanges(details, sectionsUpdate)
    }

    const handleTemplateSectionDialogResult = (updated: boolean, sectionName: string, selection: DocumentType[]) => {
        setTemplateSectionProps({
            ...templateSectionProps,
            open: false,
            sectionName: '',
            selected: [],
        })
        if (!updated || !sectionName) return
        const existing = getSection(sectionName)
        if (selection.length === 0) {
            if (!existing) return
            deleteSection(existing.applicationSection.name)
            return
        }
        const updatedSection = getUpdatedSection(id!, sectionName, selection, existing)
        const except = getSectionsExcept(sectionName)
        const updatedSections = [...except, updatedSection].sort((a, b) =>
            a.applicationSection.name.localeCompare(b.applicationSection.name)
        )
        setSections(updatedSections)
        updateUnsavedChanges(details, updatedSections)
    }

    if (!id)
        return (
            <Typography variant="h6" gutterBottom component="div" color="error">
                The requested template could not be found
            </Typography>
        )

    if (!contextData.user.isAdminOrRecruiter()) return <div />

    return (
        <Grid sx={{ mt: 0, height: '100%', width: '100%' }}>
            <ConfirmDialogUnsavedChanges
                showDialog={showUnsavedChangesPrompt}
                confirmNavigation={confirmNavigationWithSave}
                cancelNavigation={cancelNavigation}
            />
            <ConfirmDialog {...confirmDialogProps} />
            <TemplateSectionDialog {...templateSectionProps} />
            <PageHeader title={`Template Checklist${unsavedChanges ? '*' : ''}`} />
            <PageInfo message={pageError} color="error" />

            <TextField
                autoFocus
                fullWidth
                variant="outlined"
                size="small"
                margin="dense"
                inputProps={{ maxLength: 256 }}
                name="name"
                label="Checklist Name*"
                value={backdropOpen ? 'loading...' : details?.name ?? ''}
                onChange={(e) => setChecklistName(e.target.value)}
                error={backdropOpen ? false : details?.name ? false : true}
                helperText={backdropOpen ? false : details?.name ? '' : 'required field'}
            />

            <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddSection} sx={{ mt: 1, mr: 1 }}>
                ADD NEW DOCUMENT GROUP
            </Button>

            {sections.length === 0 ? (
                <Typography
                    sx={{
                        color: 'text.secondary',
                        fontSize: 'smaller',
                        mt: 2,
                        ml: 1,
                    }}
                >
                    It seems there is nothing here at the moment. Click 'Add a new document group' to begin building the
                    checklist.
                </Typography>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                    {sections.map((value, index) => (
                        <TemplateSectionEdit
                            key={index}
                            data={value}
                            editSection={() => handleEditSection(value)}
                            deleteSection={() => handleDeleteSection(value)}
                        />
                    ))}
                </Box>
            )}

            <Box
                sx={{
                    mt: 2,
                    with: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                }}
            >
                <Button variant="outlined" onClick={navigateToList}>
                    Cancel
                </Button>
                {/* {templateApplied === false && (
                    <Tooltip title="There are pending changes that have not been applied to checklists that reference this template">
                        <Button
                            variant="contained"
                            startIcon={<SyncAltIcon />}
                            onClick={applyTemplate}
                            color="error"
                            sx={{ ml: 1 }}
                        >
                            APPLY TEMPLATE
                        </Button>
                    </Tooltip>
                )} */}
                <Button variant="contained" onClick={() => saveChanges(false, details)} sx={{ ml: 1 }}>
                    Save
                </Button>
            </Box>

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}
