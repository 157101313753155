export const aws_exports_dev = {
    openid_domain: 'lmd.au.auth0.com',
    openid_client_id: 'vBzwvYVLRtT2gCPRPY5Wdw2DeozFyCzN',
    aws_project_region: 'ap-southeast-2',
    aws_appsync_authenticationType: 'OPENID_CONNECT', // "AMAZON_COGNITO_USER_POOLS", //"AMAZON_COGNITO_USER_POOLS", "AWS_IAM"
    aws_appsync_region: 'ap-southeast-2',
    aws_appsync_graphqlEndpoint: 'https://vrjifzlphfhsbca7fhhrmti6pm.appsync-api.ap-southeast-2.amazonaws.com/graphql',
    aws_user_files_s3_bucket_region: 'ap-southeast-2',
    aws_user_files_s3_bucket: 'ledge-med-dev-ledgemeddevbucketfefab2db-1jwxrxic8k5ae',
    aws_cognito_region: 'ap-southeast-2',
    aws_cognito_identity_pool_id: 'ap-southeast-2:fc233ca9-0fb4-47ed-a4c8-7c6eba0600c1',
}

export const aws_exports_test = {
    openid_domain: 'ledgemedstaging.au.auth0.com',
    openid_client_id: 'GWAKOcZPOrAUScKf4lCVH3bWw2dp2ert',
    aws_project_region: 'ap-southeast-2',
    aws_appsync_authenticationType: 'OPENID_CONNECT',
    aws_appsync_region: 'ap-southeast-2',
    aws_appsync_graphqlEndpoint: 'https://5t65xaukm5apdpp5lvq5aj52ma.appsync-api.ap-southeast-2.amazonaws.com/graphql',
    aws_user_files_s3_bucket_region: 'ap-southeast-2',
    aws_user_files_s3_bucket: 'ledge-med-test-ledgemedtestbucket8ff6dac6-bz5e93lnpnug',
    aws_cognito_region: 'ap-southeast-2',
    aws_cognito_identity_pool_id: 'ap-southeast-2:25c0f191-0d69-449e-9055-c9ae714c37ab',
}

export const aws_exports_prod = {
    openid_domain: 'ledgemed.au.auth0.com',
    openid_client_id: '3KbimWHdYknFhHg3oELUEiXk53Ad9SF2',
    aws_project_region: 'ap-southeast-2',
    aws_appsync_authenticationType: 'OPENID_CONNECT',
    aws_appsync_region: 'ap-southeast-2',
    aws_appsync_graphqlEndpoint: 'https://xus3oy2vtrf65oddwkx66fdfj4.appsync-api.ap-southeast-2.amazonaws.com/graphql',
    aws_user_files_s3_bucket_region: 'ap-southeast-2',
    aws_user_files_s3_bucket: 'ledge-med-prod-ledgemedbucket219cc2d0-h19p827j9tzm',
    aws_cognito_region: 'ap-southeast-2',
    aws_cognito_identity_pool_id: 'ap-southeast-2:0c1f909d-23c5-41fa-a3a8-6c4c77be5139',
    
}

const production_address = 'app.ledgemed.com.au'
const test_address = 'test.ledgemed.com.au'
// const dev_address = 'localhost'

export const getAwsExports = () => {
    const hostname = window.location.hostname
    console.log(hostname)
    if (hostname.includes(production_address)) throw aws_exports_prod
    if (hostname.includes(test_address)) return aws_exports_test
    return aws_exports_dev
}
