import { useState, useEffect, useContext } from 'react'
import MDEditor from '@uiw/react-md-editor'
import {
    Autocomplete,
    Backdrop,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    FormControlLabel,
    FormHelperText,
    CircularProgress,
    DialogActions,
    TextField,
    Tooltip,
    Typography,
    FormLabel,
} from '@mui/material'
import { callApi } from '../../common/apiUtils'
import { isValidPathName, getDocumentTypePaths } from '../../common/typeUtils'
import { getErrorMessage } from '../../common/errorUtils'
import { EditResult, EditProps } from '../../common/types'
import { UserContext } from '../../common/userContext'
import { PageInfo } from '../common/pageInfo'
import { DocumentType, DocumentTypeInput } from '../../API'
import { createDocumentType, updateDocumentType } from '../../graphql/mutations'

const { v4: uuid } = require('uuid')

export default function DocumentTypeEdit(props: EditProps) {
    //const { id } = useParams();
    //const editPathName = decodeURIComponent(id ?? "");
    const editId = props.id
    const isNew = editId === 'add'
    const contextData = useContext(UserContext)
    const paths = getDocumentTypePaths(contextData.documentTypes)

    const initialDocumentType: DocumentTypeInput = {
        id: uuid(),
        pathName: '/',
        hasMany: false,
        applicationSpecific: false,
        info: undefined,
        url: undefined,
    }

    const [documentTypeInput, setDocumentTypeInput] = useState<DocumentTypeInput>(initialDocumentType)
    const [backdropOpen, setBackdropOpen] = useState<boolean>(false)
    const [pageError, setPageError] = useState<string>('')
    const [pathError, setPathError] = useState<string>('')

    useEffect(() => {
        const initialise = () => {
            if (isNew) {
                return
            }
            const documentType = contextData.documentTypes.find((d) => d.id === editId)
            if (!documentType) {
                if (editId !== '') setPageError('The requested document type could not be found')
                return
            }
            const existingDocumentType: DocumentTypeInput = {
                id: documentType.id,
                pathName: documentType.pathName,
                hasMany: documentType.hasMany,
                applicationSpecific: documentType.applicationSpecific,
                info: documentType.info,
                url: documentType.url,
            }
            setDocumentTypeInput(existingDocumentType)
        }
        initialise()
    }, [editId, isNew, contextData.documentTypes])

    const pathNameChanged = (event: any, value: any) => {
        if (!event || !value) return
        const pathName = String(value)
        setDocumentTypeInput({
            ...documentTypeInput,
            pathName: pathName,
        })
    }

    const infoChanged = (info: string | undefined) => {
        setDocumentTypeInput({
            ...documentTypeInput,
            info: info ? info : '',
        })
    }

    const hasManyChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDocumentTypeInput({
            ...documentTypeInput,
            hasMany: event.target.checked,
        })
    }

    const applicationSpecificChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDocumentTypeInput({
            ...documentTypeInput,
            applicationSpecific: event.target.checked,
        })
    }

    const handlePathNameKeyDown = (e: any) => {
        if (e && e.key.match('^[a-zA-Z0-9]$') === false) {
            e.preventDefault()
        }
    }

    const handleCancel = () => {
        props.callback(EditResult.Cancelled, undefined)
    }

    const handleSubmit = async () => {
        let issueCount = 0

        if (isValidPathName(documentTypeInput.pathName)) {
            setPathError('')
        } else {
            setPathError(
                documentTypeInput.pathName ? 'invalid document type - must include only A-Z a-z 0-9' : 'required field'
            )
            issueCount = issueCount + 1
        }

        const existing = contextData.documentTypes.find((e) => e.pathName === documentTypeInput.pathName)

        if (existing && (isNew || existing.id !== documentTypeInput.id)) {
            setPathError('duplicate document type')
            issueCount = issueCount + 1
        }

        if (issueCount !== 0) return

        setBackdropOpen(true)

        const [operation, operationName] = isNew
            ? [createDocumentType, 'createDocumentType']
            : [updateDocumentType, 'updateDocumentType']
        const updatedDocumentType = await callApi<DocumentType>(contextData.user, operationName, {
            query: operation,
            variables: { item: documentTypeInput },
        })

        if (!updatedDocumentType.Result) {
            setBackdropOpen(false)
            setPageError(getErrorMessage('updating the document type', updatedDocumentType.Error))
            return
        }

        const documentTypes = contextData.documentTypes
        const updated = updatedDocumentType.Result
        if (isNew) {
            contextData.setDocumentTypes([...documentTypes, updated])
        } else {
            const exceptUpdated = documentTypes.filter((documentType) => documentType.id !== updated.id)
            contextData.setDocumentTypes([...exceptUpdated, updated])
        }

        setBackdropOpen(false)
        props.callback(isNew ? EditResult.Added : EditResult.Updated, updated)
    }

    // { /* border: '1px dashed grey',  */ }
    if (!contextData.user.isAdminOrRecruiter()) return <div />

    return (
        <Box
            sx={{
                display: 'flex-column',
                justifyContent: 'flex-end',
                minWidth: '400px',
            }}
        >
            {/* <PageHeader
                title={isNew ? "Add Document Type" : "Update Document Type"}
            /> */}
            <Typography variant="h5" gutterBottom component="div">
                {isNew ? 'Add Document Type' : 'Update Document Type'}
            </Typography>
            <hr color="error" />
            <PageInfo message={pageError} color="error" />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormLabel>Document Type</FormLabel>
                <Autocomplete
                    freeSolo
                    options={paths}
                    value={documentTypeInput.pathName}
                    onInputChange={pathNameChanged}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                autoFocus
                                fullWidth
                                variant="outlined"
                                size="small"
                                margin="dense"
                                onKeyDown={handlePathNameKeyDown}
                            />
                        )
                    }}
                />
                <FormHelperText error={pathError ? true : false}>{pathError}</FormHelperText>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                    control={<Checkbox checked={documentTypeInput.hasMany} onChange={hasManyChanged} size="small" />}
                    label="Has Many"
                />
                <Tooltip title="e.g. a cover letter - of false then these documents will not be automatically matched when preparing a new application">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={documentTypeInput.applicationSpecific ?? false}
                                onChange={applicationSpecificChanged}
                                size="small"
                            />
                        }
                        label="Application Specific"
                    />
                </Tooltip>
            </Box>

            {/* https://www.npmjs.com/package/@uiw/react-md-editor */}
            <Box
                sx={{
                    mt: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'auto',
                }}
            >
                <FormLabel>Document Type Information</FormLabel>
                <Box component="div" data-color-mode="light" sx={{ mt: 1 }}>
                    <MDEditor
                        value={documentTypeInput.info ?? ''}
                        onChange={infoChanged}
                        preview="edit"
                        // style={{
                        //     background: "#FFFFFF",
                        //     color: "#494949",
                        //     borderRadius: "10",
                        //     padding: "5px",
                        // }}
                        //height={500}
                    />
                </Box>
            </Box>

            <DialogActions>
                <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit}>
                    Save
                </Button>
            </DialogActions>

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export function DocumentTypeEditDialog(props: EditProps) {
    return (
        <Dialog open={props.open}>
            <DialogContent>
                <DocumentTypeEdit {...props} />
            </DialogContent>
        </Dialog>
    )
}
