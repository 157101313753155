import { Box, FormControl, InputLabel, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import auLocale from 'date-fns/locale/en-AU'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import { CitizenshipStatus, UserInput } from '../../API'
import * as common from './profileCommon'
import * as utils from '../../common/typeUtils'

export type ApplicationSectionProps = {
    userInput: UserInput
}

export function ProfileAdditional({ userInput, setValue, gridItemWidth }: common.ProfileSectionProps) {
    return (
        <Box
            sx={{
                boxSizing: 'border-box',
                p: 1,
                borderRadius: '5px',
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Memberships and Additional Information</Typography>
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            margin="dense"
                            inputProps={{ maxLength: 256 }}
                            name="wwccNumber"
                            label="WWCC Number"
                            value={userInput.wwccNumber ?? ''}
                            onChange={(e) => setValue(e.target.name, e.target.value)}
                            sx={{ backgroundColor: 'white', width: '66%' }}
                        />

                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={auLocale}>
                            <DatePicker
                                value={utils.parseISOAndConvertToDate(userInput.wwccExpiry)}
                                onChange={(date) =>
                                    utils.tryParseDateAndSetISOValue(date, (s) => setValue('wwccExpiry', s))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        margin="dense"
                                        //inputProps={{ maxLength: 256 }}
                                        name="wwccExpiry"
                                        label="Expiry"
                                        sx={{ backgroundColor: 'white', ml: 1, width: '34%' }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        inputProps={{ maxLength: 256 }}
                        name="ahpraNumber"
                        label="AHPRA Number"
                        value={userInput.ahpraNumber ?? ''}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                        sx={{ backgroundColor: 'white' }}
                    />
                </Grid>
                <Grid item xs={gridItemWidth}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        inputProps={{ maxLength: 256 }}
                        name="prescriberNumber"
                        label="Prescriber Number"
                        value={userInput.prescriberNumber ?? ''}
                        onChange={(e) => setValue(e.target.name, e.target.value)}
                        sx={{ backgroundColor: 'white' }}
                    />
                </Grid>

                <Grid item xs={gridItemWidth}>
                    <FormControl fullWidth variant="outlined" size="small" margin="dense">
                        <InputLabel id="citizenshipStatusLabel">Citizenship Status</InputLabel>
                        <Select
                            labelId="citizenshipStatusLabel"
                            label="Citizenship Status"
                            name="citizenshipStatus"
                            value={userInput.citizenshipStatus ?? ''}
                            onChange={(e) =>
                                setValue(e.target.name, e.target.value ? CitizenshipStatus[e.target.value] : undefined)
                            }
                            MenuProps={{
                                PaperProps: { sx: { maxHeight: 200 } },
                            }}
                            sx={{ backgroundColor: 'white' }}
                        >
                            {Object.values(CitizenshipStatus).map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}
