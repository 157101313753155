import { callApi } from './/apiUtils'
import { UserContext, UserWrapper } from './userContext'
import {
    Application,
    ApplicationSection,
    ApplicationDocument,
    ApplicationInput,
    ApplicationSectionInput,
    ApplicationDocumentInput,
    Document,
    DocumentType,
} from '../API'
import { ContextDataInterface } from './userContext'
import { groupBy, toMap, filterDefined, sortDocumentsForMatching } from './typeUtils'
import { getApplicationById, convertToApplicationInput } from './applicationUtils'
import { updateApplication } from '../graphql/mutations'
import { omit, uniq, take } from 'lodash'

const { v4: uuid } = require('uuid')

export const getApplicationsByTemplateId = /* GraphQL */ `
    query GetApplicationsByTemplateId($pk: ID!) {
        getApplicationsByTemplateId(pk: $pk) {
            id
            name
            userId
            templateId
            templateVersion
            applicationSections {
                id
                applicationId
                name
                applicationDocuments {
                    id
                    applicationSectionId
                    documentTypeId
                    documentId
                }
            }
        }
    }
`

export async function applyTemplateToApplicationChecklists (context: ContextDataInterface, template:Application) : Promise<Error | undefined> {
    try {
        const applications = await callApi<Application[]>(context.user, 'getApplicationsByTemplateId', {
            query: getApplicationsByTemplateId,
            variables: { pk: template.id },
        })
        if (!applications.Result) {
            return applications.Error
        }
        
        const results = await Promise.all(applications.Result.map((d) => doApplyTemplate(context, template, d, undefined)))
        const firstError = results.find((r) => r instanceof Error)
        if (firstError) {
            return firstError as Error
        }
        return undefined
    } catch(e) {
        console.log(e)
        return e as Error
    }
}

export const applyTemplateToApplicationChecklist = async (context: ContextDataInterface, applicationId:string) : Promise<Error | ApplicationInput> => {
    try {
        
        const applicationQuery = await callApi<Application>(context.user, 'getApplicationById', {
            query: getApplicationById,
            variables: { pk: applicationId },
        })

        const application = applicationQuery.Result

        if (!application) 
            return applicationQuery.Error!

        if (application.userId !== context.ledgeUser?.id) 
            return new Error('The application could not be updated')

        if(!application.templateId)
            return convertToApplicationInput(application, false, "")

        const template = context.templates.find((t) => t.id === application.templateId)

        if(!template) {
            const converted = convertToApplicationInput(application, false, "")
            const update = omit(converted, ["templateId", "templateVersion"])
            const updatedApplication = await callApi<Application>(context.user, "updateApplication", {
                query: updateApplication,
                variables: { item: update },
            })
            return updatedApplication.Error  ? updatedApplication.Error : update 
        }

        const userDocuments = context.ledgeUser?.id === application.userId ? context.documents : undefined 
        
        return await doApplyTemplate(context, template, application, userDocuments)
    } catch(e) {
        console.log(e)
        return e as Error
    }
}

const doApplyTemplate = async (context: ContextDataInterface, template:Application, application:Application, userDocuments: Document[] | undefined) : Promise<Error | ApplicationInput> => {
    const [changed, update] = syncApplication(context, template, application)
    if(!changed && template.updated === application.templateVersion) return update
    const [matchedChanged, updatedAndMatched] = userDocuments ? matchDocuments(update, userDocuments) : [false, update]
    const updatedApplication = await callApi<Application>(context.user, "updateApplication", {
        query: updateApplication,
        variables: { item: updatedAndMatched },
    })
    return updatedApplication.Error ? updatedApplication.Error : updatedAndMatched
}

const syncApplication = (context: ContextDataInterface, template:Application, application:Application) : [boolean, ApplicationInput] => {
    
    const templateSectionsMap = toMap(template.applicationSections, (d) => d.name, false)
    const applicationSectionsMap = toMap(application.applicationSections, (d) => d.name, false)
    const secionNames = uniq([ ...templateSectionsMap.keys(), ...applicationSectionsMap.keys()])
    var sections: ApplicationSectionInput[] = []
    var changed = template.name != application.name

    for(const sectionName of secionNames) {
        if(!templateSectionsMap.has(sectionName)) {
            changed = true
            continue
        }
        const templateSection = templateSectionsMap.get(sectionName)!
        const appSection = applicationSectionsMap.get(sectionName)

        if(!appSection){
            changed = true
            const newSection = createApplicationSection(application.id, templateSection)
            sections.push(newSection)
            continue
        }

        const [sectionChanged, sectionInput] = syncApplicationSection(context, application.id, templateSection, appSection)
        changed = changed || sectionChanged
        sections.push(sectionInput) 
    }

    const result: ApplicationInput = {
        id: application.id,
        userId: application.userId,
        name: template.name,
        templateId: template.id,
        templateVersion: template.updated,
        applicationSections: sections,
    }

    return [changed, result]
}

function createApplicationSection(applicationId: string, templateSection:ApplicationSection) {
        
    const sectionId = uuid()
    const documents = templateSection.applicationDocuments.map((d) => {
        const documentInput: ApplicationDocumentInput = {
            isNew: true,
            id: uuid(),
            applicationSectionId: sectionId,
            documentTypeId: d.documentTypeId,
        }
        return documentInput
    })

    const sectionInput: ApplicationSectionInput = {
        isNew: true,
        id: sectionId,
        applicationId: applicationId,
        name: templateSection.name,
        applicationDocuments: documents.sort((a, b) => a.id.localeCompare(b.id)),
    }
    return sectionInput
}

function syncApplicationSection(context: ContextDataInterface, applicationId:string, templateSection:ApplicationSection, applicationSection:ApplicationSection) : [boolean, ApplicationSectionInput] {
    
    const templateSectionDocumentsMap = groupBy(templateSection.applicationDocuments, (d) => d.documentTypeId)
    const applicationSectionDocumentsMap = groupBy(applicationSection.applicationDocuments, (d) => d.documentTypeId)
    const documentTypeIds = uniq([ ...templateSectionDocumentsMap.keys(), ...applicationSectionDocumentsMap.keys()])
    var sectionDocs: ApplicationDocumentInput[] = []
    var changed = false

    for(const documentTypeId of documentTypeIds) {
        if(!templateSectionDocumentsMap.has(documentTypeId)) {
            changed = true
            continue
        }
        const templateDocs = templateSectionDocumentsMap.get(documentTypeId) ?? []
        const appDocs = applicationSectionDocumentsMap.get(documentTypeId) ?? []
        const [typeChanged, typeDocs] = syncApplicationSectionDocs(context, applicationSection.id, documentTypeId, templateDocs, appDocs)
        changed = changed || typeChanged
        sectionDocs = [...sectionDocs, ...typeDocs]
    }

    const result: ApplicationSectionInput = {
        isNew: false,
        id: applicationSection.id,
        name: applicationSection.name,
        applicationId: applicationId,
        applicationDocuments: sectionDocs,
    }

    return [changed, result]
}

function syncApplicationSectionDocs (context: ContextDataInterface, sectionId: string, documentTypeId: string, templateDocs:ApplicationDocument[], applicationDocs:ApplicationDocument[]) : [boolean, ApplicationDocumentInput[]] {
    var changed = false
    var result = applicationDocs.map((d) => {
        const input: ApplicationDocumentInput = {
            isNew: false,
            id: d.id,
            applicationSectionId: sectionId,
            documentTypeId: documentTypeId,
            documentId: d.documentId,
        }
        return input
    })

    for(var i = 0; i < templateDocs.length - applicationDocs.length; ++i){
        const missing: ApplicationDocumentInput = {
            isNew: true,
            id: uuid(),
            applicationSectionId: sectionId,
            documentTypeId: documentTypeId,
        }
        result.push(missing)
        changed = true
    }
    
    if(templateDocs.length === applicationDocs.length)
        return [changed, result]
    
    changed = true
    result = take(sortDocumentsForRemoval(context, result), templateDocs.length)
    return [changed, result]
}

export function sortDocumentsForRemoval(context: ContextDataInterface, applicationDocuments: ApplicationDocumentInput[]): ApplicationDocumentInput[] {
    const sorted = [...applicationDocuments].sort((a, b) => {
        const typeCompare = a.documentTypeId.localeCompare(b.documentTypeId);
        if (typeCompare !== 0) throw new Error('Document type mismatch!');
        const documentA = a.documentId ? context.documents.find((d) => d.id === a.documentId) : undefined
        const documentB = b.documentId ? context.documents.find((d) => d.id === b.documentId) : undefined
        if(!documentA) return 1
        if(!documentB) return -1
        let expiresCompare = 0;
        try {
            const noExpiry = Number.MAX_SAFE_INTEGER;
            const aExpires = documentA.expires ? Date.parse(documentA.expires) : noExpiry;
            const bExpires = documentB.expires ? Date.parse(documentB.expires) : noExpiry;
            expiresCompare =
                aExpires === bExpires ? 0 : aExpires < bExpires ? 1 : -1;
        } catch (error) {
            console.log(error);
        }
        if (expiresCompare !== 0) return expiresCompare;
        const updatedCompare = documentA.updated > documentB.updated ? -1 : 1;
        return updatedCompare;
    });
    return sorted;
}

export function matchDocuments(
    application: ApplicationInput,
    userDocuments: Document[],
) : [boolean, ApplicationInput] {
    let takenDocuments: string[] = []
    const sortedDocuments = sortDocumentsForMatching(userDocuments.filter((d) => d.userId === application.userId))
    for(const section of application.applicationSections) {
        const takenIds = filterDefined(section.applicationDocuments.map((ad) => ad.documentId))
        takenDocuments = [ ...takenDocuments,  ...takenIds]
    }
    let changed = false
    const sections: ApplicationSectionInput[] = []
    for(const section of application.applicationSections) {
        const [nextChanged, nextSection] = matchDocumentsToSection(section, sortedDocuments, takenDocuments)
        sections.push(nextSection)
        changed = changed || nextChanged
    }
    return [changed, { ...application, applicationSections: sections }]
}

function matchDocumentsToSection(
    section: ApplicationSectionInput,
    sortedDocuments: Document[],
    takenDocuments: string[]
) : [boolean, ApplicationSectionInput] {
    let changed = false
    let sectionDocuments: ApplicationDocumentInput[] = []
    for (let d of section.applicationDocuments) {
        let existingDocument = d.documentId ? sortedDocuments.find((u) => u.id === d.documentId) : undefined
        if(existingDocument) {
            sectionDocuments.push(d)
            continue
        }
        const matching = tryFindMatchingDocument(d.documentTypeId, sortedDocuments, takenDocuments)
        if(!matching) {
            sectionDocuments.push(d)
            continue
        }
        changed = true
        takenDocuments.push(matching.id)
        sectionDocuments.push({ ...d, documentId: matching.id })
    }
    return [changed, { ...section, applicationDocuments: sectionDocuments}]
}

function tryFindMatchingDocument(documentTypeId: string, userDocuments: Document[], taken: string[]) {
    // try to lookup suitable document to fill the slot
    return userDocuments.find((d) => {
        // if (documentType.applicationSpecific) return false
        // if (hasExpired(d.expires)) return false; Expired documents have lower sort order, see sortDocumentsForMatching
        if (d.documentTypeId !== documentTypeId) return false
        if (taken.includes(d.id)) return false
        return true
    })
}
