/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser($item: UserInput!) {
    createUser(item: $item) {
      id
      firstName
      lastName
      phone
      email
      dateOfBirth
      ledgeRecUser
      level
      yearsOfExperience
      currentJobTitle
      currentJobType
      seekingLevels
      seekingSpecialities
      bankName
      bankBsb
      bankAccountNumber
      superannuationNumber
      taxFileNumber
      abnNumber
      wwccNumber
      wwccExpiry
      ahpraNumber
      prescriberNumber
      citizenshipStatus
      activeCampaignContactId
      activeCampaignPendingUpdate
      created
      updated
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($item: UserInput!) {
    updateUser(item: $item) {
      id
      firstName
      lastName
      phone
      email
      dateOfBirth
      ledgeRecUser
      level
      yearsOfExperience
      currentJobTitle
      currentJobType
      seekingLevels
      seekingSpecialities
      bankName
      bankBsb
      bankAccountNumber
      superannuationNumber
      taxFileNumber
      abnNumber
      wwccNumber
      wwccExpiry
      ahpraNumber
      prescriberNumber
      citizenshipStatus
      activeCampaignContactId
      activeCampaignPendingUpdate
      created
      updated
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($pk: ID!) {
    deleteUser(pk: $pk) {
      id
      firstName
      lastName
      phone
      email
      dateOfBirth
      ledgeRecUser
      level
      yearsOfExperience
      currentJobTitle
      currentJobType
      seekingLevels
      seekingSpecialities
      bankName
      bankBsb
      bankAccountNumber
      superannuationNumber
      taxFileNumber
      abnNumber
      wwccNumber
      wwccExpiry
      ahpraNumber
      prescriberNumber
      citizenshipStatus
      activeCampaignContactId
      activeCampaignPendingUpdate
      created
      updated
    }
  }
`;
export const updateUserLedgeRec = /* GraphQL */ `
  mutation UpdateUserLedgeRec($item: UserLedgeRecInput!) {
    updateUserLedgeRec(item: $item) {
      id
      firstName
      lastName
      phone
      email
      dateOfBirth
      ledgeRecUser
      level
      yearsOfExperience
      currentJobTitle
      currentJobType
      seekingLevels
      seekingSpecialities
      bankName
      bankBsb
      bankAccountNumber
      superannuationNumber
      taxFileNumber
      abnNumber
      wwccNumber
      wwccExpiry
      ahpraNumber
      prescriberNumber
      citizenshipStatus
      activeCampaignContactId
      activeCampaignPendingUpdate
      created
      updated
    }
  }
`;
export const updateUserAuth0 = /* GraphQL */ `
  mutation UpdateUserAuth0($item: UserAuth0Input!) {
    updateUserAuth0(item: $item) {
      id
      useMfa
    }
  }
`;
export const resendUserAuth0EmailVerification = /* GraphQL */ `
  mutation ResendUserAuth0EmailVerification($pk: ID!) {
    resendUserAuth0EmailVerification(pk: $pk)
  }
`;
export const createDocumentType = /* GraphQL */ `
  mutation CreateDocumentType($item: DocumentTypeInput!) {
    createDocumentType(item: $item) {
      id
      pathName
      hasMany
      applicationSpecific
      info
      url
    }
  }
`;
export const updateDocumentType = /* GraphQL */ `
  mutation UpdateDocumentType($item: DocumentTypeInput!) {
    updateDocumentType(item: $item) {
      id
      pathName
      hasMany
      applicationSpecific
      info
      url
    }
  }
`;
export const deleteDocumentType = /* GraphQL */ `
  mutation DeleteDocumentType($pk: String!) {
    deleteDocumentType(pk: $pk) {
      id
      pathName
      hasMany
      applicationSpecific
      info
      url
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument($item: DocumentInput!) {
    createDocument(item: $item) {
      id
      userId
      documentName
      documentTypeId
      uploaded
      originalFileName
      contentType
      expires
      details
      updated
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument($item: DocumentInput!) {
    updateDocument(item: $item) {
      id
      userId
      documentName
      documentTypeId
      uploaded
      originalFileName
      contentType
      expires
      details
      updated
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument($pk: ID!) {
    deleteDocument(pk: $pk) {
      id
      userId
      documentName
      documentTypeId
      uploaded
      originalFileName
      contentType
      expires
      details
      updated
    }
  }
`;
export const createApplication = /* GraphQL */ `
  mutation CreateApplication($item: ApplicationInput!) {
    createApplication(item: $item) {
      id
      name
      userId
      templateId
      templateVersion
      templateApplied
      created
      updated
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication($item: ApplicationInput!) {
    updateApplication(item: $item) {
      id
      name
      userId
      templateId
      templateVersion
      templateApplied
      created
      updated
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication($pk: ID!) {
    deleteApplication(pk: $pk) {
      id
      name
      userId
      templateId
      templateVersion
      templateApplied
      created
      updated
    }
  }
`;
export const createApplicationTemplate = /* GraphQL */ `
  mutation CreateApplicationTemplate($item: ApplicationInput!) {
    createApplicationTemplate(item: $item) {
      id
      name
      userId
      templateId
      templateVersion
      templateApplied
      created
      updated
    }
  }
`;
export const updateApplicationTemplate = /* GraphQL */ `
  mutation UpdateApplicationTemplate($item: ApplicationInput!) {
    updateApplicationTemplate(item: $item) {
      id
      name
      userId
      templateId
      templateVersion
      templateApplied
      created
      updated
    }
  }
`;
export const updateApplicationTemplateApplied = /* GraphQL */ `
  mutation UpdateApplicationTemplateApplied($pk: ID!) {
    updateApplicationTemplateApplied(pk: $pk) {
      id
      name
      userId
      templateId
      templateVersion
      templateApplied
      created
      updated
    }
  }
`;
export const deleteApplicationTemplate = /* GraphQL */ `
  mutation DeleteApplicationTemplate($pk: ID!) {
    deleteApplicationTemplate(pk: $pk) {
      id
      name
      userId
      templateId
      templateVersion
      templateApplied
      created
      updated
    }
  }
`;
