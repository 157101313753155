import { useState, useContext, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined'
import { Backdrop, Box, CircularProgress, Tooltip } from '@mui/material'
import {
    useGridApiRef,
    DataGridPro,
    GridActionsCellItem,
    GridRowParams,
    GridCallbackDetails,
    GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import { downloadAndZip } from '../../common/documentUtils'
import {
    ApplicationEditMode,
    getApplicationPercentComplete,
    getApplicationDocuments,
    isApplicationUpToDate,
} from './applicationCommon'
import { UserContext } from '../../common/userContext'
import { Application } from '../../API'
import { callApi, retrieveApplications } from '../../common/apiUtils'
import { ApplicationAddDialog } from './applicationAdd'
import { ConfirmDialog, ConfirmDialogProps, ConfirmDialogPropsClosed } from '../common/confirmDialog'
import { deleteApplication } from '../../graphql/mutations'
import * as utils from '../../common/typeUtils'

export const ApplicationsGrid = forwardRef((props, ref) => {
    const navigate = useNavigate()
    const contextData = useContext(UserContext)
    const apiRef = useGridApiRef()
    const [rows, setRows] = useState<Application[]>([])
    const [processing, setProcessing] = useState<boolean>(true)
    const [backdropOpen, setBackdropOpen] = useState<boolean>(false)
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps>(ConfirmDialogPropsClosed)
    const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false)

    useImperativeHandle(ref, () => ({
        addChecklist() {
            setAddDialogOpen(true)
        },
    }))

    const rowDoubleClick = (params: GridRowParams, event: any, details: GridCallbackDetails) => {
        event.defaultMuiPrevented = true
        navigate(`/applicationEdit/${params.row.id}/${ApplicationEditMode[ApplicationEditMode.Edit]}`)
    }

    const updateRow = (id: string) => (event: any) => {
        event.stopPropagation()
        navigate(`/applicationEdit/${id}/${ApplicationEditMode[ApplicationEditMode.Edit]}`)
    }

    const getRowDocuments = (id: string) => {
        const row = apiRef.current.getRow(id) as Application
        return getApplicationDocuments(row, contextData)
    }

    const downloadRow = (id: string) => async (event: any) => {
        setBackdropOpen(true)
        const documents = getRowDocuments(id)
        const error = await downloadAndZip(contextData, documents, contextData.documentTypes)
        if (error) {
            console.log(error.message)
        }
        setBackdropOpen(false)
    }

    const deleteRow = (id: string) => async (event: any) => {
        event.stopPropagation()
        const row = apiRef.current.getRow(id)
        if (!row) {
            console.log(`Edit row for ${id} was null!`)
            return
        }
        let props: ConfirmDialogProps = {
            open: true,
            title: 'Delete Checklist',
            description: `Are you sure that you would like to delete '${row.name}'?`,
            action: 'Delete',
            data: id,
            callback: handleConfirmDialogResult,
        }
        setConfirmDialogProps(props)
    }

    const getRowClassName = (params: any) => {
        const isComplete = getApplicationPercentComplete(params.row, contextData) >= 100
        const isUpToDate = isApplicationUpToDate(params.row, contextData)
        if (isUpToDate && isComplete) return ''
        const isExpired = utils.hasExpired(params.row.dueDate)
        return isExpired || !isUpToDate ? 'super-app-theme--expired' : ''
    }

    const handleConfirmDialogResult = async (action: string, data: any, result: boolean) => {
        setConfirmDialogProps(ConfirmDialogPropsClosed)

        if (!result || action !== 'Delete') return

        const row = apiRef.current.getRow(data as string)
        if (!row) {
            console.log(`Edit row for ${data} was null!`)
            return
        }

        try {
            setProcessing(true)
            const deletedApplication = await callApi<Application>(contextData.user, 'deleteApplication', {
                query: deleteApplication,
                variables: { pk: row.id },
            })
            if (!deletedApplication.Result) {
                console.log('Failed to delete the record: ' + deletedApplication.Error)
            }
            const except = rows.filter((r) => r.id !== row.id)
            setRows(except)
        } catch (error) {
            console.log(error)
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        let isCancelled = false
        const getRows = async () => {
            retrieveApplications(contextData.user, contextData.ledgeUser?.id ?? '').then((applications) => {
                if (isCancelled) return
                if (applications.Result) {
                    setRows(applications.Result)
                }
                setProcessing(false)
            })
        }
        getRows()
        return () => {
            isCancelled = true
        }
    }, [contextData.user])

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            hide: true,
            type: 'string',
        },
        {
            field: 'name',
            headerName: 'Health Network',
            flex: 1,
        },
        {
            field: 'created', // just using a dummy unused field here so that the header is rendered ok, revisit
            headerName: 'Status',
            width: 100,
            resizable: false,
            renderCell: (params: GridRenderCellParams<string>) => {
                const percentComplete = getApplicationPercentComplete(params.row, contextData)
                return percentComplete >= 100 ? (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <p>{percentComplete}%</p>
                        <CheckCircleIcon color="success" sx={{ ml: '3px', fontSize: 'medium' }} />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <p>{percentComplete}%</p>
                        <WarningOutlinedIcon color="warning" sx={{ ml: '3px', fontSize: 'medium' }} />
                    </Box>
                )
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 150,
            resizable: false,
            cellClassName: 'actions',
            getActions: ({ id }: any) => {
                const nothingToDownload = getRowDocuments(id).length === 0

                return [
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Edit">
                                <EditIcon />
                            </Tooltip>
                        }
                        label="Edit"
                        className="textPrimary"
                        onClick={updateRow(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Download">
                                <DownloadIcon />
                            </Tooltip>
                        }
                        label="Download"
                        className="textPrimary"
                        onClick={downloadRow(id)}
                        color="inherit"
                        disabled={nothingToDownload}
                        //readOnly={nothingToDownload}
                    />,
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Delete">
                                <DeleteIcon />
                            </Tooltip>
                        }
                        label="Delete"
                        onClick={deleteRow(id)}
                        color="inherit"
                    />,
                ]
            },
        },
    ]

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 1,
                width: 1,
                minHeight: '300px',
                minWidth: '300px',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                '& .super-app-theme--expired': {
                    bgcolor: 'mistyrose',
                },
            }}
        >
            <ConfirmDialog {...confirmDialogProps} />
            <ApplicationAddDialog handleClose={(e) => setAddDialogOpen(false)} open={addDialogOpen} existing={rows} />
            <DataGridPro
                autoPageSize
                pagination
                disableColumnReorder={true}
                rows={rows}
                columns={columns}
                apiRef={apiRef}
                loading={processing}
                editMode="row"
                getRowClassName={(params) => `${getRowClassName(params)}`}
                onRowDoubleClick={rowDoubleClick}
                initialState={{
                    // pinnedColumns: { right: ["actions"] },
                    sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                    },
                }}
            />
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
})
