import { useState, useContext } from 'react'
import { Box, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { UserContext } from '../../common/userContext'
import {
    useGridApiRef,
    DataGridPro,
    GridRowParams,
    GridCallbackDetails,
    GridActionsCellItem,
    GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import { deleteDocumentType } from '../../graphql/mutations'
import { DocumentTypeLabel } from '../common/documentTypeLabel'
import { DocumentTypeEditDialog } from './documentTypeEdit'
import { ConfirmDialog, ConfirmDialogProps, ConfirmDialogPropsClosed } from '../common/confirmDialog'
import { EditResult, EditProps, EditPropsClosed } from '../../common/types'
import { PageHeader } from '../common/pageHeader'
import { DocumentType } from '../../API'
import { callApi } from '../../common/apiUtils'

export default function DocumentTypeAdmin() {
    const apiRef = useGridApiRef()
    const contextData = useContext(UserContext)
    const [processing, setProcessing] = useState<boolean>(false)
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps>(ConfirmDialogPropsClosed)
    const [editDialogProps, setEditDialogProps] = useState<EditProps>(EditPropsClosed)

    const addRow = () => {
        let editProps: EditProps = {
            open: true,
            id: 'add',
            data: undefined,
            callback: handleEditDialogResult,
            handleClose: (e) => setEditDialogProps(EditPropsClosed),
        }
        setEditDialogProps(editProps)
    }

    const updateRow = (id: string) => (event: any) => {
        event.stopPropagation()
        let editProps: EditProps = {
            open: true,
            id: id,
            data: undefined,
            callback: handleEditDialogResult,
            handleClose: (e) => setEditDialogProps(EditPropsClosed),
        }
        setEditDialogProps(editProps)
    }

    const deleteRow = (id: string) => async (event: any) => {
        event.stopPropagation()
        const row = apiRef.current.getRow(id)
        if (!row) return
        let props: ConfirmDialogProps = {
            open: true,
            title: 'Delete Document Type',
            description: `Are you sure that you would like to delete the document ${row.pathName}?`,
            action: 'Delete',
            data: id,
            callback: handleConfirmDialogResult,
        }
        setConfirmDialogProps(props)
    }

    const rowDoubleClick = (params: GridRowParams, event: any, details: GridCallbackDetails) => {
        event.defaultMuiPrevented = true
        let editProps: EditProps = {
            open: true,
            id: params.row.id,
            data: undefined,
            callback: handleEditDialogResult,
            handleClose: (e) => setEditDialogProps(EditPropsClosed),
        }
        setEditDialogProps(editProps)
    }

    const handleEditDialogResult = async (editResult: EditResult, documentType: any) => {
        setEditDialogProps(EditPropsClosed)
        switch (editResult) {
            case EditResult.Added:
                if (documentType) {
                    contextData.setDocumentTypes([...contextData.documentTypes, documentType])
                }
                break
            case EditResult.Updated:
                if (document) {
                    const except = contextData.documentTypes.filter((r) => r.id !== documentType.id)
                    contextData.setDocumentTypes([...except, documentType])
                }
                break
            default:
                break
        }
    }

    const handleConfirmDialogResult = async (action: string, data: any, result: boolean) => {
        setConfirmDialogProps(ConfirmDialogPropsClosed)

        if (!result || action !== 'Delete') return

        const row = apiRef.current.getRow(data as string)
        if (!row) {
            console.log(`Edit row for ${data} was null!`)
            return
        }

        try {
            setProcessing(true)
            const deleteResult = await callApi<DocumentType>(contextData.user, 'deleteDocumentType', {
                query: deleteDocumentType,
                variables: { pk: row.id },
            })
            if (!deleteResult.Result) console.log('Error deleting document type record: ' + deleteResult.Error)
            const except = contextData.documentTypes.filter((r) => r.id !== row.id)
            contextData.setDocumentTypes(except)
        } catch (error) {
            console.log(error)
        } finally {
            setProcessing(false)
        }
    }

    const columns = [
        {
            field: 'id',
            headerName: 'id',
            hide: true,
        },
        {
            field: 'pathName',
            headerName: 'Name',
            flex: 5,
            renderCell: (params: GridRenderCellParams<string>) => <DocumentTypeLabel id={params.row.id} />,
        },
        {
            field: 'hasMany',
            headerName: 'HasMany',
            flex: 1,
            type: 'boolean',
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }: any) => {
                // const isInEditMode = apiRef.current.getRowMode(id) === "edit";
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={updateRow(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={deleteRow(id)}
                        color="inherit"
                    />,
                ]
            },
        },
    ]

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                minHeight: '300px',
                minWidth: '300px',
            }}
        >
            <ConfirmDialog {...confirmDialogProps} />
            <DocumentTypeEditDialog {...editDialogProps} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                }}
            >
                <PageHeader title={`Document\u00a0Types`} />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-end',
                        mr: 0,
                        mb: 1,
                    }}
                >
                    <Button variant="contained" startIcon={<AddIcon />} onClick={addRow} sx={{ mr: 0 }}>
                        Add
                    </Button>
                </Box>
            </Box>
            <DataGridPro
                autoPageSize
                pagination
                loading={processing}
                rows={contextData.documentTypes}
                columns={columns}
                apiRef={apiRef}
                // getRowId={(row) => `${row.pathName}`}
                editMode="row"
                onRowDoubleClick={rowDoubleClick}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'pathName', sort: 'asc' }],
                    },
                }}
            />
        </Box>
    )
}
