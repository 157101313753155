import { useState, useContext } from 'react'
import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TableCell,
    Tooltip,
    Typography,
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined'
import EditIcon from '@mui/icons-material/Edit'
import {
    ApplicationSectionDocumentEditNewDialog,
    ApplicationSectionDocumentEditNewProps,
} from './applicationSectionDocumentEditNewDialog'
import {
    getDocumentName,
    getDocumentTypeName,
    getAvailableDocumentsByType,
    ApplicationSectionData,
    ApplicationDocumentData,
} from './applicationCommon'
import { viewDocument } from '../../common/documentUtils'
import { Document, DocumentType } from '../../API'
import { UserContext } from '../../common/userContext'
import { hasExpired } from '../../common/typeUtils'

export type ApplicationSectionEditProps = {
    data: ApplicationSectionData
    editSectionDocument: (applicationDocumentId: string, document: Document | undefined) => void
}

export function ApplicationSectionEdit({ data, editSectionDocument }: ApplicationSectionEditProps) {
    const contextData = useContext(UserContext)

    const sortedDocuments = data.documents.sort((a, b) =>
        getDocumentName(a.document).localeCompare(getDocumentName(b.document))
    )

    const [documentDialogProps, setDocumentDialogProps] = useState<ApplicationSectionDocumentEditNewProps>({
        open: false,
        applicationDocumentId: '',
        documentType: { pathName: '' } as DocumentType,
        selected: undefined,
        available: [],
        callback: (i, d) => {},
        handleClose: (e: any) => {},
    })

    const editDocument = (row: ApplicationDocumentData) => {
        const availableDocuments = getAvailableDocumentsByType(data, row.documentType.id, contextData)
        const editProps: ApplicationSectionDocumentEditNewProps = {
            ...documentDialogProps,
            open: true,
            applicationDocumentId: row.applicationDocument.id,
            documentType: row.documentType,
            selected: row.document,
            available: availableDocuments,
            callback: editDocumentResult,
            handleClose: (e) => setDocumentDialogProps({ ...documentDialogProps, open: false }),
        }
        setDocumentDialogProps(editProps)
    }

    const editDocumentResult = (applicationDocumentId: string, selected: Document | undefined) => {
        setDocumentDialogProps({ ...documentDialogProps, open: false })
        editSectionDocument(applicationDocumentId, selected)
    }

    const viewDocumentInNewTab = async (row: ApplicationDocumentData) => {
        if (!row.document) return

        try {
            const error = await viewDocument(contextData, row.document)
            if (error) {
                console.log(error.message)
                return
            }
        } catch (error) {
            console.log(`Unable to view document! ${error}`)
        }
    }

    const getRowStatusIcon = (row: ApplicationDocumentData) => {
        if (!row.document)
            return (
                <Tooltip title="Document missing">
                    <IconButton>
                        <ErrorOutlinedIcon color="error" sx={{ fontSize: 'medium' }} />
                    </IconButton>
                </Tooltip>
            )

        if (hasExpired(row.document.expires))
            return (
                <Tooltip title="Expired">
                    <IconButton>
                        <WarningOutlinedIcon color="warning" sx={{ fontSize: 'medium' }} />
                    </IconButton>
                </Tooltip>
            )

        if (!row.document.uploaded)
            return (
                <Tooltip title="Missing">
                    <IconButton>
                        <WarningOutlinedIcon color="warning" sx={{ fontSize: 'medium' }} />
                    </IconButton>
                </Tooltip>
            )

        return (
            <Tooltip title="Attached">
                <IconButton>
                    <CheckCircleIcon color="success" sx={{ fontSize: 'medium' }} />
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <ApplicationSectionDocumentEditNewDialog {...documentDialogProps} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mt: 2,
                    alignItems: 'center',
                    verticalAlign: 'center',
                    //border: "solid black 1px",
                }}
            >
                <Typography
                    sx={{
                        alignSelf: 'center',
                        fontSize: 'medium',
                        fontWeight: 'bolder',
                        padding: '3px 10px',
                    }}
                >
                    {data.applicationSection.name.toUpperCase()}
                </Typography>
                <div style={{ flexGrow: '1' }}></div>
            </Box>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }}>
                    <TableBody>
                        {sortedDocuments.map((row, index) => (
                            <TableRow key={index} onDoubleClick={() => viewDocumentInNewTab(row)}>
                                <TableCell sx={{ padding: '2px 16px', width: '100%' }}>
                                    {getDocumentName(row.document)}{' '}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        padding: '2px 16px',
                                        color: '#CCCCCC',
                                        //fontStyle: "italic",
                                        textAlign: 'right',
                                    }}
                                >
                                    {getDocumentTypeName(row.documentType).replace(/ /g, '\u00a0')}
                                </TableCell>
                                <TableCell
                                    sx={{ padding: '2px 2px' }}
                                    //align="right"
                                >
                                    <Tooltip title="Swap Document">
                                        <IconButton onClick={() => editDocument(row)}>
                                            <EditIcon color="inherit" sx={{ fontSize: 'medium' }} />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell
                                    sx={{ padding: '2px 2px' }}
                                    //align="right"
                                >
                                    <IconButton
                                        onClick={() => viewDocumentInNewTab(row)}
                                        disabled={row.document === undefined}
                                    >
                                        <VisibilityIcon color="inherit" sx={{ fontSize: 'medium' }} />
                                    </IconButton>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        padding: '2px 2px',
                                        bgcolor: 'background.default',
                                    }}
                                >
                                    <Box>{getRowStatusIcon(row)}</Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
