import { useState, useContext, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Backdrop,
    Box,
    Button,
    Divider,
    Grid,
    CircularProgress,
    LinearProgress,
    Typography,
    Tooltip,
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import { callApi } from '../../common/apiUtils'
import { ConfirmDialogUnsavedChanges } from '../common/confirmDialogUnsavedChanges'
import { UserContext } from '../../common/userContext'
import { useCallbackPrompt } from '../../common/useCallbackPrompt'
import { getErrorMessage } from '../../common/errorUtils'
import { PageHeader } from '../common/pageHeader'
import { PageInfo } from '../common/pageInfo'
import { ApplicationSectionEdit } from './applicationSection'
import { Application, ApplicationInput, Document } from '../../API'
import { createApplication, updateApplication } from '../../graphql/mutations'
import * as common from './applicationCommon'
import { applyTemplateToApplicationChecklist, matchDocuments } from '../../common/applicationSync'
import { downloadAndZip } from '../../common/documentUtils'

function goToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

function getSectionsExcept(name: string, sections: common.ApplicationSectionData[]): common.ApplicationSectionData[] {
    return [...sections.filter((s) => s.applicationSection.name !== name)]
}

export default function ApplicationEdit() {
    const { id, mode } = useParams()
    const editMode = common.ApplicationEditMode[mode ?? 'AddFromTemplate']
    const isNew = editMode === common.ApplicationEditMode.AddFromTemplate
    const navigate = useNavigate()
    const navigateAway = useRef(false)
    const contextData = useContext(UserContext)
    const [applicationOrig, setApplicationOrig] = useState<ApplicationInput>({} as ApplicationInput)
    const [applicationDetails, setApplicationDetails] = useState<ApplicationInput>()
    const [pageInfo, setPageInfo] = useState({ message: '', color: '' })
    const [loading, setLoading] = useState<boolean>(true)
    const [backdropOpen, setBackdropOpen] = useState<boolean>(true)
    const [sections, setSections] = useState<common.ApplicationSectionData[]>([])
    const [percentComplete, setPercentComplete] = useState(0)
    const [navigateRequest, setNavigateRequest] = useState(new Date())
    const [unsavedChanges, setUnsavedChanges] = useState(isNew)
    const [isUpToDate, setIsUpToDate] = useState(true)
    const [showUnsavedChangesPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(unsavedChanges)

    const getUpdatedApplication = () => {
        if (!applicationDetails) return undefined
        const sectionInputs = common.getSectionInputs(applicationDetails.id, sections)
        return {
            ...applicationDetails,
            applicationSections: sectionInputs,
        } as ApplicationInput
    }

    function updateUnsavedChanges(details: ApplicationInput | undefined, sections: common.ApplicationSectionData[]) {
        if (!details) return
        if (isNew) return
        const hasUnsavedChanges = common.hasChanges(applicationOrig, details, sections)
        setUnsavedChanges(hasUnsavedChanges)
    }

    const confirmNavigationWithSave = async (saveFirst: boolean) => {
        if (saveFirst) await saveChanges(false) // THis will navigate after the save
        confirmNavigation()
    }

    const initialise = (applicationInput: ApplicationInput) => {
        setApplicationOrig(applicationInput)
        setApplicationDetails(applicationInput)
        const documentTypes = contextData.documentTypes
        const documents = contextData.documents.filter((d) => d.userId === applicationInput.userId)
        const sectionDatas = applicationInput.applicationSections.map((s) =>
            common.initialiseSectionData(isNew, s, documentTypes, documents)
        )
        setSections(sectionDatas)
        setPercentComplete(common.getPercentComplete(sectionDatas))
        setIsUpToDate(common.isApplicationUpToDate(applicationInput, contextData))
    }

    useEffect(() => {
        const getApplication = async () => {
            const result = await common.getApplicationInput(
                contextData.user,
                id ?? '',
                editMode,
                contextData.ledgeUser?.id ?? ''
            )
            if (!result.applicationInput) {
                setBackdropOpen(false)
                setPageInfo({ message: getErrorMessage('retrieving the application', result.error), color: 'error' })
                return
            }
            initialise(result.applicationInput)
            setBackdropOpen(false)
        }
        if (loading) {
            setLoading(false)
            getApplication()
        }
    }, [contextData, id, isNew, loading, mode, editMode, sections, setSections, percentComplete, setPercentComplete])

    useEffect(() => {
        if (navigateAway.current && unsavedChanges === false) {
            const returnPage =
                contextData.user.sub !== contextData.ledgeUser?.id && contextData.user.isAdminOrRecruiter()
                    ? `/candidate/${contextData.ledgeUser?.id}`
                    : '/applications'
            navigate(returnPage)
        }
    }, [unsavedChanges, navigateRequest, navigate])

    const navigateToList = () => {
        // Use effect above waits for unsavedChanges to be set to turn off the confirm dialog before navigating
        navigateAway.current = true
        setUnsavedChanges(false)
        setNavigateRequest(new Date())
    }

    const download = async () => {
        try {
            setBackdropOpen(true)
            const application = getUpdatedApplication()
            if (!application) return
            const documents = common.getApplicationDocuments(application, contextData)
            const error = await downloadAndZip(contextData, documents, contextData.documentTypes)
            if (error) {
                console.log(error.message)
            }
        } finally {
            setBackdropOpen(false)
        }
    }

    const saveChanges = async (navigateAfterSave: boolean) => {
        const updated = getUpdatedApplication()
        if (!updated) return
        setBackdropOpen(true)
        const [operation, operationName] = isNew
            ? [createApplication, 'createApplication']
            : [updateApplication, 'updateApplication']
        const updatedApplication = await callApi<Application>(contextData.user, operationName, {
            query: operation,
            variables: { item: updated },
        })
        if (!updatedApplication.Result) {
            setPageInfo({
                message: getErrorMessage('updating the application', updatedApplication.Error),
                color: 'error',
            })
            setBackdropOpen(false)
            return
        }
        setBackdropOpen(false)
        if (navigateAfterSave) navigateToList()
    }

    const handleEditSectionDocument = (
        section: common.ApplicationSectionData,
        applicationDocumentId: string,
        document: Document | undefined
    ) => {
        const updatedSection = common.updatedSectionDocument(applicationDetails?.userId ?? '', section, applicationDocumentId, document)
        const except = getSectionsExcept(section.applicationSection.name, sections)
        const updatedSections = [...except, updatedSection].sort((a, b) =>
            a.applicationSection.name.localeCompare(b.applicationSection.name)
        )
        setSections(updatedSections)
        setPercentComplete(common.getPercentComplete(updatedSections))
        updateUnsavedChanges(applicationDetails, updatedSections)
    }

    const tryMatchDocuments = () => {
        try {
            const application = getUpdatedApplication()
            if (!application) return
            const [changed, result] = matchDocuments(application, contextData.documents)
            if (!changed) {
                setPageInfo({ message: 'No new matches found', color: 'error' })
                return
            }
            setPageInfo({
                message: 'Matches found!',
                color: 'primary.main',
            })
            initialise(result)

        } catch (e) {
            setPageInfo({ message: getErrorMessage('matching documents', e), color: 'error' })
        } finally {
            goToTop()
        }
    }

    const applyTemplate = async () => {
        try {
            if (isNew || !id) return
            const result = await applyTemplateToApplicationChecklist(contextData, id)
            if (result instanceof Error) {
                setPageInfo({ message: getErrorMessage('applying the template', result as Error), color: 'error' })
                return
            }
            initialise(result as ApplicationInput)
        } finally {
            setBackdropOpen(false)
        }
    }

    if (!id)
        return (
            <Typography variant="h6" gutterBottom component="div" color="error">
                The requested application checklist could not be found
            </Typography>
        )

    return (
        <Grid sx={{ mt: 0, height: '100%', width: '100%' }}>
            <ConfirmDialogUnsavedChanges
                showDialog={showUnsavedChangesPrompt}
                confirmNavigation={confirmNavigationWithSave}
                cancelNavigation={cancelNavigation}
            />

            {applicationDetails && <PageHeader title={`${applicationDetails.name}${unsavedChanges ? '*' : ''}`} />}

            <PageInfo {...pageInfo} />

            <p>
                To ensure you are ready to begin work when we find a placement for you, we have compiled the following
                checklist of documents that are relevant for this health network.
            </p>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    mb: 1,
                }}
            >
                <LinearProgress
                    variant="determinate"
                    value={percentComplete}
                    sx={{
                        flexGrow: 1,
                        height: '15px',
                        borderRadius: '5px',
                    }}
                />
                <Typography
                    sx={{
                        color: 'text.secondary',
                        fontSize: 'smaller',
                        alignSelf: 'center',
                        ml: 1,
                        mr: 1,
                    }}
                >
                    {percentComplete}% Complete
                </Typography>
            </Box>

            <Divider orientation="horizontal" flexItem sx={{ ml: '3px', mr: '3px', mt: 1 }} />

            {sections.length === 0 ? (
                <Typography
                    sx={{
                        color: 'text.secondary',
                        fontSize: 'smaller',
                        mt: 1,
                        ml: 1,
                    }}
                >
                    It seems there is nothing here at the moment. Add a new document grouping to begin building a new
                    checklist.
                </Typography>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {sections.map((value, index) => (
                        <ApplicationSectionEdit
                            key={index}
                            data={value}
                            editSectionDocument={(id, doc) => handleEditSectionDocument(value, id, doc)}
                        />
                    ))}
                </Box>
            )}

            <Box
                sx={{
                    mt: 2,
                    with: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                }}
            >
                <Button variant="outlined" onClick={download} startIcon={<DownloadIcon />}>
                    Download
                </Button>
                <Button variant="outlined" onClick={navigateToList} sx={{ ml: 1 }}>
                    Cancel
                </Button>
                <Tooltip title="Try to match missing items from your documents">
                    <Button variant="outlined" onClick={tryMatchDocuments} sx={{ ml: 1 }}>
                        RESYNC
                    </Button>
                </Tooltip>
                <Button variant="contained" onClick={() => saveChanges(true)} sx={{ ml: 1 }}>
                    Save
                </Button>
                {isUpToDate === false && (
                    <Tooltip title="The template checklist has changed since you created this checklist. Click to update this checklist to match current template version">
                        <Button
                            variant="contained"
                            startIcon={<SyncAltIcon />}
                            onClick={applyTemplate}
                            color="error"
                            sx={{ ml: 1 }}
                        >
                            SYNC CHECKLIST                        
                        </Button>
                    </Tooltip>
                )}
            </Box>

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Grid>
    )
}
