import React from 'react'
import { Box, Input, Link, Stack } from '@mui/material'

// https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929

export type DragAndDropProps = {
    multiple: boolean
    replace: boolean
    message?: string | undefined
    handleDrop: (files: File[]) => void
}

export function DragAndDrop(props: DragAndDropProps) {
    const defaultMessage = props.replace ? `Drop Here To Replace` : `Drop Here`
    const dragCounter = React.useRef(0)
    const [drag, setDrag] = React.useState(false)
    const dropRef = React.createRef<HTMLDivElement>()
    const inputRef = React.useRef<HTMLInputElement>()

    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter.current++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDrag(true)
        }
    }

    const handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter.current--
        if (dragCounter.current === 0) {
            setDrag(false)
        }
    }

    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDrag(false)
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            dropFiles(e.dataTransfer.files)
            e.dataTransfer.clearData()
            dragCounter.current = 0
        }
    }

    const handleClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        inputRef.current?.click()
    }

    const documentChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        dropFiles(event?.target?.files)
    }

    const dropFiles = (files: FileList | null) => {
        if (files && files.length > 0) {
            const filesArray = [...files]
            props.handleDrop(filesArray)
        }
        if(!inputRef.current) return
        inputRef.current.value = ''
    }

    React.useEffect(() => {
        const div = dropRef.current
        if (!div) return
        div.addEventListener('dragenter', handleDragIn)
        div.addEventListener('dragleave', handleDragOut)
        div.addEventListener('dragover', handleDrag)
        div.addEventListener('drop', handleDrop)

        const removeListeners = () => {
            div.removeEventListener('dragenter', handleDragIn)
            div.removeEventListener('dragleave', handleDragOut)
            div.removeEventListener('dragover', handleDrag)
            div.removeEventListener('drop', handleDrop)
        }
        return removeListeners
    })

    return (
        <div style={{ display: 'inline-block', position: 'relative', width: '100%' }} ref={dropRef}>
            {/* {drag && ( */}
            {
                <div
                    style={{
                        border: drag ? 'dashed grey 4px' : 'dashed grey 2px',
                        backgroundColor: 'rgba(245,245,245,.8)',
                        borderRadius: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'grey',
                        //position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        minHeight: '100px',
                        zIndex: 9999,
                    }}
                >
                    <Stack direction="column" spacing={1} sx={{ alignItems: 'center' }}>
                        <Box>{props.message ? props.message : defaultMessage}</Box>
                        <Box sx={{ fontStyle: 'italic', fontSize: 'small' }}>
                            <Input
                                type="file"
                                onChange={documentChanged}
                                sx={{ display: 'none' }}
                                inputRef={inputRef}
                            />
                            Or{' '}
                            <Link href="#" underline="always" onClick={handleClick}>
                                click
                            </Link>{' '}
                            to upload
                        </Box>
                    </Stack>
                </div>
            }
            {/* {props.children}  */}
        </div>
    )
}
